import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { GlobalContext } from '../../context/GlobalContext';
import SkeletonTable from '../../components/Table/SkeletonTable';
import InspectionTable from './InspectionTable';
import { StyledDiv } from './styled-components';

const Inspections = () => {
  const params = useParams();
  const history = useNavigate();
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    inspections, loading, user_sites, user_organizations
  } = useContext(GlobalContext);
  const [filteredInspections, setFilteredInspections] = useState(inspections);
  const [netsuiteSite, setNetsuiteSite] = useState(null);
  const [netsuiteOrganization, setNetsuiteOrganization] = useState(null);
  const [shouldRender, setShouldRender] = useState(!loading && !!netsuiteSite && !!netsuiteOrganization);

  const goToOrgList = () => {
    setNetsuiteSite(null);
    history(`/orgs/${netsuiteOrganization.id}`);
  };

  const goToStart = () => {
    setFilteredInspections(inspections);
    setNetsuiteSite(null);
    setNetsuiteOrganization(null);
    history('/');
  };

  useEffect(() => {
    if (!loading) {
      const organization = user_organizations.find((org) => String(org.id) === String(params.customerId));
      const inspectionOrganization = inspections?.find((i) => String(i.org.id) === String(params.customerId));
      const site = user_sites.find((siteArg) => String(siteArg.id) === String(params.id));
      const inspectionSite = inspections?.find((s) => String(s.site.id) === String(params.id));

      // if we can't find the site or organization for some reason, default to the
      // firt org or site we find in the inspections list
      setNetsuiteOrganization(organization || inspectionOrganization?.org);
      setNetsuiteSite(site || inspectionSite?.site);
    }
  }, [params, inspections, loading, user_organizations, user_sites]);

  useEffect(() => {
    if (netsuiteOrganization && netsuiteSite) {
      const filtered = inspections?.filter(
        (i) => String(i.site.id) === String(netsuiteSite.id)
          && i.status?.code?.toLowerCase() === 'complete'
      );
      setFilteredInspections(filtered || []);
    } else {
      setFilteredInspections([]);
    }
    setShouldRender(!loading && !!netsuiteSite && !!netsuiteOrganization);
  }, [netsuiteOrganization, netsuiteSite, inspections]);

  return shouldRender ? (
    <StyledDiv>
      <InspectionTable
        inspections={filteredInspections}
        user_organization={netsuiteOrganization}
        user_site={netsuiteSite}
        onOrgBreadcrumbClick={goToOrgList}
        onInspectionBreadcrumbClick={goToStart}
        history={history}
      />
    </StyledDiv>
  ) : (
    <StyledDiv>
      <SkeletonTable isInspections />
    </StyledDiv>
  );
};

export default Inspections;
