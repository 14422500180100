/* eslint-disable max-len */
import {
  urlPrefix
} from '../shared/url';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer '
};

export const getContactVersionById = async (contactId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}contacts.api.axil.ai/contacts/${contactId}`, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`Error fetching contact: ${response.statusText}`);
    }

    const responseData = await response.json();

    const { version } = responseData;

    if (!version) {
      throw new Error('Version not found in the response body');
    }

    return version;
  } catch (error) {
    console.error('Error fetching contact version:', error);
    throw error;
  }
};

export const getInspectionVersionById = async (inspectionId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}inspections.api.axil.ai/inspections/${inspectionId}`, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`Error fetching inspection: ${response.statusText}`);
    }

    const responseData = await response.json();

    const { version } = responseData;

    if (!version) {
      throw new Error('Version not found in the response body');
    }

    return version;
  } catch (error) {
    console.error('Error fetching inspection version:', error);
    throw error;
  }
};

export const fetchFileData = async (fileId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}files.api.axil.ai/files/${fileId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    }
    throw new Error('Failed to fetch resource');
  } catch (error) {
    console.error('Error fetching file data:', error);
    return null;
  }
};

export const fetchFilesForInspection = async (parentId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}files.api.axil.ai/${parentId}/files`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    }
    throw new Error('Failed to fetch resource');
  } catch (error) {
    console.error('Error fetching file data:', error);
    return null;
  }
};

export const fetchFileUrl = async (fileId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}files.api.axil.ai/files/${fileId}/presigned-url/inline/True`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    }
    throw new Error('Failed to fetch resource');
  } catch (error) {
    console.error('Error fetching file data:', error);
    throw error;
  }
};
