/* eslint @typescript-eslint/no-unused-vars: off */
import React, { useContext } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

import { GlobalContext } from '../../context/GlobalContext';
import { homeSections } from '../../shared/constants';
import './styles/TableTabs.css';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  &&&& .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
    margin: 0;
    margin-left: 40px;
    margin-right: 20px;
    color: #bcbcbc;
    font-size: 24px !important;
    font-weight: 600;
  }

  .ant-tabs-ink-bar {
    bottom: 8px !important;
    background-color: black !important;
  }

  .ant-tabs-ink-bar:after {
    bottom: 8px !important;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #f8f8f8;
  }
`;

const TabOption = styled.span`
  display: inline-block;
  height: 100%;

  p {
    margin: 0 0 2px 0;
    height: fit-content;
  }
`;

const CountCircle = styled.span`
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #dbdbdb;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  color: #000;
  font-size: 10px;
  margin-left: 12px;
  vertical-align: middle;
  font-weight: 200;
`;

const homeSectionMap = {
  [homeSections.IN_PROGRESS]: 'In progress',
  [homeSections.COMPLETE]: 'Complete'
};

const TableTabs = ({
  extra,
  onRowCompleted,
  handleInProgress,
  data,
  filters,
  counts,
  tableComponent: Table
}) => {
  const { activeHomeSection, setActiveHomeSection } = useContext(GlobalContext);
  if (
    !data
    || !filters
    || !counts
    || data.length !== filters.length
    || data.length !== counts.length
  ) {
    return null;
  }

  return (
    <StyledTabs
      activeKey={homeSectionMap[activeHomeSection]}
      className="custom-tabs"
      onChange={(key) => {
        const newSection = Object.entries(homeSectionMap).find(([_, val]) => val === key);
        setActiveHomeSection(newSection?.at(0));
      }}
      hasCountCircle={filters.some(
        (filter) => filter.title === 'In progress' && counts[filters.indexOf(filter)] > 0
      )}
    >
      {data.map((tabData, index) => {
        let currentOnRow;
        if (filters[index].title === 'In progress') {
          currentOnRow = handleInProgress;
        } else if (filters[index].title === 'Complete') {
          currentOnRow = onRowCompleted;
        }

        return (
          <TabPane
            tab={(
              <TabOption>
                <p>{filters[index].title}</p>
                {filters[index].title === 'In progress' && counts[index] > 0 && (
                  <CountCircle>{counts[index]}</CountCircle>
                )}
              </TabOption>
            )}
            key={filters[index].title}
          >
            {extra}
            <Table data={tabData} columns={filters[index].columns} onRow={currentOnRow} />
          </TabPane>
        );
      })}
    </StyledTabs>
  );
};

export default TableTabs;
