import { deleteInspectionTransactions, deleteLocalInspectionById, getVersionByInspectionId } from '../dexie/operations';
import { API_INSPECTION } from '../shared/url';

const deleteInspection = async (token: string, id: string) => {
  await deleteInspectionTransactions(id);
  const { version } = await getVersionByInspectionId(id) ?? {};
  const requestOptions = {
    method: 'DELETE',
    inspectionId: id,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'if-Match': version?.toString() || ''
    }
  };
  await deleteLocalInspectionById(id);

  const endpoint = `${API_INSPECTION}/${id}`;
  try {
    const response = await fetch(endpoint, requestOptions);
    if (!response.ok) {
      return response;
    }
    return await response.json();
  } catch (error) {
    return error;
  }
};

export default deleteInspection;
