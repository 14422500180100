import React, { useState, useContext, useEffect } from 'react';
import { Tooltip } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { IoChevronForward } from 'react-icons/io5';
import { Tabs, Tab, Icon } from '@flogistix/flo-ui';

import { GlobalContext } from '../../../context/GlobalContext';
import { InspectionContext } from '../../../context/InspectionContext';
import { addKeys, getFilesFromInspection } from '../../../shared/utils';
import { global } from '../../../shared/colors';
import FilesSkeleton from '../FilesSkeleton/FilesSkeleton';
import Table from '../../../components/Table/Table';
import Status from '../../../components/Status/Status';
import { Header } from '../../../shared/styled-components';
import {
  IconWrapper,
  HeaderWrapper,
  InspectionID,
  StyledFile,
  StyledTableButtonDiv,
  BatchItems,
  StyledColumnDiv
} from './styled-components';
import { INFINITE } from '../../../shared/constants';
import { urlPrefix } from '../../../shared/url';
import EmptyState from '../../../components/Table/EmptyState/EmptyState';
import './FilteredFilesTable.scss';

const FilteredFilesTable = ({
  user_organization,
  user_site,
  onOrgBreadcrumbClick,
  onInspectionBreadcrumbClick,
  flogistix_id,
  inspId,
  OnOrgLocationBreadcrumbClick
}) => {
  const [typeTab, setTypeTab] = useState('1');
  const [data, setData] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [leakFiles, setLeakFiles] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { inspection } = useContext(InspectionContext);
  const {
    inspectionsReloadIsPending,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_admin,
    triggerGlobalNotification,
    token
  } = useContext(GlobalContext);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const fetchFiles = async () => {
    setFilesLoading(true);
    try {
      const response = await fetch(`https://${urlPrefix}files.api.axil.ai/${inspId}/files`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const files = await response.json();
      setData(files);
    } catch (error) {
      setData([]);
      triggerGlobalNotification(
        'error',
        'There was a problem loading your files. Try refreshing the page. '
          + 'If the problem persists, please contact support for help.',
        'Failure to load files',
        INFINITE
      );
    }
    setFilesLoading(false);
  };

  const fetchFileById = async (fileId, display = false) => {
    try {
      const response = await fetch(
        `https://${urlPrefix}files.api.axil.ai/files/${fileId}/presigned-url/inline/${display}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      const file = await response.json();
      return file;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const inspectionFiles = getFilesFromInspection(inspection);

  const leakAssociations = ['leak', 'leakRepair'];

  const images = data && addKeys(
    data.filter((file) => file.fileType === 'image')
  );

  const videos = data && addKeys(data?.filter((file) => {
    const foundFile = inspectionFiles.find((f) => f.id === file.id);
    return file.fileType === 'video' && !leakAssociations.includes(foundFile?.association);
  }));
  const leaks = leakFiles && addKeys(leakFiles);
  const reports = data && addKeys(data?.filter((file) => {
    const foundFile = inspectionFiles.find((f) => f.id === file.id);
    if (file.fileType === 'video') return false;
    if (file.fileType === 'image') return false;
    if (leakAssociations.includes(foundFile?.association)) return false;
    return true;
  }));

  const refreshFiles = async () => {
    if (!inspId || !token) {
      return;
    }
    await fetchFiles();
    setSelectedRowKeys([]);
  };

  const viewFile = async (fileId) => {
    try {
      const file = await fetchFileById(fileId, true);
      window.open(file.url, '_blank');
      return file;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const handleMultiDownload = async (e, singleFile = null) => {
    e.stopPropagation();
    e.preventDefault();
    if (singleFile) {
      try {
        const result = await fetchFileById(singleFile);
        window.open(result.url, '_blank');
      } catch (error) {
        console.error(error);
      }
    } else {
      selectedRowKeys.map(async (fileId) => {
        try {
          const result = await fetchFileById(fileId);
          window.open(result.url, '_blank');
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  useEffect(() => {
    const leakFileMap = {};
    if (inspection && Array.isArray(inspection.leaks)) {
      inspection.leaks.forEach((leak) => {
        if (leak.files && Array.isArray(leak.files)) {
          leak.files.forEach((file) => {
            leakFileMap[file.id] = leak.tagNumber || '';
          });
        }
      });
    }
    const leakFileIds = Object.keys(leakFileMap);
    if (leakFileIds.length > 0) {
      const filteredLeakFiles = data
        .filter((file) => leakFileIds.includes(file.id))
        .map((file) => ({
          ...file,
          tagNumber: leakFileMap[file.id]
        }));
      setLeakFiles(filteredLeakFiles);
    } else {
      setLeakFiles([]);
    }
  }, [data, inspection]);

  useEffect(() => {
    if (inspId) {
      fetchFiles();
    }
    if (inspectionsReloadIsPending) {
      refreshFiles();
    }
  }, [isAuthenticated, getAccessTokenSilently, inspectionsReloadIsPending, inspId]);

  const clientColumns = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '100%',
      ellipsis: true,
      sortable: true,
      render: (_, record) => (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {record.name}
          </button>
        </StyledFile>
      )
    }
  ];

  const clientColumnsWithTagId = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '70%',
      ellipsis: true,
      sortable: true,
      render: (_, record) => (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {record.name}
          </button>
        </StyledFile>
      )
    },
    {
      title: 'Tag Id',
      dataIndex: 'tagNumber',
      key: 'tagNumber',
      width: '30%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (
        <StyledColumnDiv>
          {value || 'No listed tag id'}
          <div className="icon-group">
            <Tooltip title="Download Single File" color={global.White}>
              <button
                className="download-button"
                data-cy="Download"
                aria-label="Download Selected Files"
                type="button"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <Icon iconName="Download" width={16} height={16} />
              </button>
            </Tooltip>
          </div>
        </StyledColumnDiv>
      )
    }
  ];

  const adminColumns = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '100%',
      ellipsis: true,
      sortable: true,
      render: (displayValue, record) => (
        <StyledColumnDiv>
          <StyledFile>
            <button
              target="_blank"
              aria-label="View File"
              type="submit"
              onClick={() => viewFile(record.id)}
            >
              {displayValue}
            </button>
          </StyledFile>
          <div className="icon-group">
            <Tooltip title="Download Single File" color={global.White}>
              <button
                className="download-button"
                data-cy="Download"
                aria-label="Download Selected Files"
                type="button"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <Icon iconName="Download" width={16} height={16} />
              </button>
            </Tooltip>
          </div>
        </StyledColumnDiv>
      )
    }
  ];

  const adminColumnsWithTagId = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '70%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {value}
          </button>
        </StyledFile>
      )
    },
    {
      title: 'Tag Id',
      dataIndex: 'tagNumber',
      key: 'tagNumber',
      width: '30%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (
        <IconWrapper>
          {record?.tagNumber || 'no tag id on record'}
          <div className="icon-group">
            <Tooltip title="Download Single File" color={global.White}>
              <button
                className="download-button"
                data-cy="Download"
                aria-label="Download Selected Files"
                type="button"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <Icon iconName="Download" width={16} height={16} />
              </button>
            </Tooltip>
          </div>
        </IconWrapper>
      )
    }
  ];

  const videoColumn = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '100%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (
        <IconWrapper>
          <StyledFile>
            <button
              target="_blank"
              aria-label="View File"
              type="submit"
              onClick={() => viewFile(record.id)}
            >
              {value}
            </button>
          </StyledFile>
          {is_admin && (
            <div className="icon-group">
              <Tooltip title="Download Single File" color={global.White}>
                <button
                  className="download-button"
                  data-cy="Download"
                  aria-label="Download Selected Files"
                  type="button"
                  onClick={(e) => handleMultiDownload(e, record.id)}
                >
                  <Icon iconName="Download" width={16} height={16} />
                </button>
              </Tooltip>
            </div>
          )}
        </IconWrapper>
      )
    }
  ];

  const handleTabChange = (tabKey) => {
    setTypeTab(tabKey);
    setSelectedRowKeys([]);
  };

  const selectAll = (items) => {
    if (items.length && selectedRowKeys.length === items.length) {
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(items.map((i) => i.id));
    }
  };

  return (
    <div className="filtered-files-table">
      <HeaderWrapper>
        <div>
          <Header>{flogistix_id}</Header>
          <Status />
        </div>
        <div className="breadcrumbs">
          <button
            type="button"
            className="inspection-breadcrumb"
            id="InspectionsBreadcrumbFiles"
            onClick={onInspectionBreadcrumbClick}
            aria-label="Inspections"
          >
            Inspections
          </button>
          <IoChevronForward className="breadcrumb-chevron" />
          <button
            type="button"
            className="breadcrumb"
            id="OrgBreadcrumbFiles"
            onClick={onOrgBreadcrumbClick}
            aria-label="Orgs"
          >
            {user_organization.name}
          </button>
          <IoChevronForward className="breadcrumb-chevron" />
          <button
            type="button"
            className="breadcrumb"
            id="SiteBreadcrumbFiles"
            onClick={OnOrgLocationBreadcrumbClick}
            aria-label="Sites"
          >
            {user_site.name}
          </button>
          <IoChevronForward className="breadcrumb-chevron" />
          <InspectionID id="FlogistixId">
            {flogistix_id}
          </InspectionID>
        </div>
      </HeaderWrapper>

      <Tabs className="filter-table-tabs" activeKey={typeTab} onSelect={handleTabChange} headerSize="h5">
        <Tab
          eventKey="1"
          title={(
            <div className="image-tab-icon">
              <div>Reports</div>
            </div>
          )}
        >
          {filesLoading && <FilesSkeleton justTable />}
          {!filesLoading && (!reports || reports.length === 0) && <EmptyState />}
          {!filesLoading && reports && reports.length > 0 && (
            <>
              <StyledTableButtonDiv>
                <label className="checkbox-group-select-all">
                  <input
                    type="checkbox"
                    checked={reports.length && selectedRowKeys.length === reports.length}
                    onChange={() => selectAll(reports)}
                  />
                  <span className="select-all-checkbox" />
                </label>
                <Tooltip title="Download Selected Files" color={global.White}>
                  <button
                    className="download-button-all"
                    data-cy="Download"
                    aria-label="Download Selected Files"
                    type="button"
                    onClick={(e) => handleMultiDownload(e)}
                  >
                    <Icon iconName="Download" width={16} height={16} />
                  </button>
                </Tooltip>
              </StyledTableButtonDiv>
              <Table
                columns={is_admin ? adminColumns : clientColumns}
                data={addKeys(reports) || ''}
                // Controlled selection from the parent
                selectedKeys={selectedRowKeys}
                onRowSelectionChange={setSelectedRowKeys}
                checkbox
                isUpload
                searchPlaceholder="Filter by display name"
                searchable
              />
            </>
          )}
        </Tab>

        <Tab
          eventKey="2"
          title={(
            <div className="image-tab-icon">
              <div>Videos</div>
            </div>
          )}
        >
          {filesLoading && <FilesSkeleton tableLocation="videos" justTable />}
          {!filesLoading && (!videos || videos.length === 0) && <EmptyState />}
          {!filesLoading && videos && videos.length > 0 && (
            <>
              <BatchItems>
                <label className="checkbox-group-select-all">
                  <input
                    type="checkbox"
                    checked={videos.length && selectedRowKeys.length === videos.length}
                    onChange={() => selectAll(videos)}
                  />
                  <span className="select-all-checkbox" />
                </label>
                <Tooltip title="Download Selected Files" color={global.White}>
                  <button
                    className="download-button-all"
                    aria-label="Download Selected Files"
                    type="button"
                    onClick={(e) => handleMultiDownload(e)}
                  >
                    <Icon iconName="Download" width={16} height={16} />
                  </button>
                </Tooltip>
              </BatchItems>
              <Table
                columns={videoColumn}
                data={videos}
                selectedKeys={selectedRowKeys}
                onRowSelectionChange={setSelectedRowKeys}
                checkbox
                isUpload
                searchable
              />
            </>
          )}
        </Tab>
        {inspection?.inspectionType === 'FACILITY' && (
          <Tab
            eventKey="3"
            title={(
              <div className="image-tab-icon">
                <div>Leaks</div>
              </div>
            )}
          >
            {filesLoading && typeTab === '3' && <FilesSkeleton tableLocation="leaks" justTable />}
            {!filesLoading && (!leaks || leaks.length === 0) && <EmptyState />}
            {!filesLoading && leaks && leaks.length > 0 && (
              <>
                <BatchItems>
                  <label className="checkbox-group-select-all">
                    <input
                      type="checkbox"
                      checked={leaks.length && selectedRowKeys.length === leaks.length}
                      onChange={() => selectAll(leaks)}
                    />
                    <span className="select-all-checkbox" />
                  </label>
                  <Tooltip title="Download Selected Files" color={global.White}>
                    <button
                      className="download-button-all"
                      aria-label="Download Selected Files"
                      type="button"
                      onClick={(e) => handleMultiDownload(e)}
                    >
                      <Icon iconName="Download" width={16} height={16} />
                    </button>
                  </Tooltip>
                </BatchItems>
                <Table
                  columns={is_admin ? adminColumnsWithTagId : clientColumnsWithTagId}
                  data={leaks}
                  selectedKeys={selectedRowKeys}
                  onRowSelectionChange={setSelectedRowKeys}
                  checkbox
                  isUpload
                  searchable
                />
              </>
            )}
          </Tab>
        )}
        <Tab
          eventKey="4"
          title={(
            <div className="image-tab-icon">
              <div>Images</div>
            </div>
          )}
        >
          {filesLoading && typeTab === '4' && <FilesSkeleton tableLocation="images" justTable />}
          {!filesLoading && (!images || images.length === 0) && <EmptyState />}
          {!filesLoading && images && images.length > 0 && (
            <>
              <BatchItems>
                <label className="checkbox-group-select-all">
                  <input
                    type="checkbox"
                    checked={images.length && selectedRowKeys.length === images.length}
                    onChange={() => selectAll(images)}
                  />
                  <span className="select-all-checkbox" />
                </label>
                <Tooltip title="Download Selected Files" color={global.White}>
                  <button
                    className="download-button-all"
                    aria-label="Download Selected Files"
                    type="button"
                    onClick={(e) => handleMultiDownload(e)}
                  >
                    <Icon iconName="Download" width={16} height={16} />
                  </button>
                </Tooltip>
              </BatchItems>
              <Table
                columns={is_admin ? adminColumns : clientColumns}
                data={images}
                selectedKeys={selectedRowKeys}
                onRowSelectionChange={setSelectedRowKeys}
                checkbox
                isUpload
                searchable
              />
            </>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default FilteredFilesTable;
