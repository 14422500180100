import { urlPrefix } from '../shared/url';
import { db } from '../dexie/db';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer '
};

export const v1QueryAll = async (routeSuffix, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}queries.api.axil.ai/v1/${routeSuffix}`, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`Error fetching from query service: ${response.statusText}`);
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error('Error fetching from query service:', error);
    throw error;
  }
};

export const getOrgs = async (token, setOrganizations, triggerGlobalNotification) => {
  try {
    const orgs = await v1QueryAll('orgs', token);
    setOrganizations(orgs);
  } catch (err) {
    triggerGlobalNotification(
      'error',
      'Failed to fetch orgs'
    );
  }
};

export const getSites = async (token, setSites, triggerGlobalNotification) => {
  try {
    const sites = await v1QueryAll('sites', token);
    setSites(sites);
  } catch (err) {
    triggerGlobalNotification(
      'error',
      'Failed to fetch sites'
    );
  }
};

export const getSitesAndOrgs = async (token) => {
  try {
    if (!token) {
      throw new Error('Token is required for fetching sites by org.');
    }

    const response = await v1QueryAll('orgs/sites', token);

    return response;
  } catch (error) {
    console.error('Fetch error for Sites by Org:', error);

    try {
      const cachedOrgs = await db.orgs.toArray();
      if (cachedOrgs.length === 0) {
        throw new Error('No orgs found in cache.');
      }
      return cachedOrgs;
    } catch (dbError) {
      console.error('Error retrieving orgs from IndexedDB:', dbError);
      throw dbError;
    }
  }
};

export const getNewInspections = async (token) => {
  try {
    const response = await v1QueryAll('inspections', token);
    return response;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

// eslint-disable-next-line consistent-return
export const getNewInstruments = async (token) => {
  try {
    if (!token) {
      throw new Error('Token is required for fetching Instruments.');
    }
    const instruments = await v1QueryAll('surveyInstruments', token);

    return instruments;
  } catch (error) {
    console.error('Fetch error for New Instruments:', error);

    try {
      const cachedInstruments = await db.instruments.toArray();
      if (cachedInstruments.length === 0) {
        throw new Error('No Instruments found in cache.');
      }
      return cachedInstruments;
    } catch (dbError) {
      console.error('Error retrieving Instruments from IndexedDB:', dbError);
      throw dbError;
    }
  }
};

// eslint-disable-next-line consistent-return
export const getNewInspectors = async (token) => {
  try {
    if (!token) {
      throw new Error('Token is required for fetching inspectors.');
    }
    const inspectors = await v1QueryAll('inspectors', token);

    return inspectors;
  } catch (error) {
    console.error('Fetch error for New Inspectors:', error);

    try {
      const cachedInspectors = await db.inspectors.toArray();
      if (cachedInspectors.length === 0) {
        throw new Error('No inspectors found in cache.');
      }
      return cachedInspectors;
    } catch (dbError) {
      console.error('Error retrieving inspectors from IndexedDB:', dbError);
      throw dbError;
    }
  }
};
