import React, {
  useRef, useState, useEffect, useContext
} from 'react';
import { Dropdown } from '@flogistix/flo-ui';

import { InspectionContext } from '../../../context/InspectionContext';
import { fetchFileUrl } from '../../../services/airmethaneApi';
import DeleteConfirmationModal from '../../Modals/DeleteConfirmationModal';
import { GlobalContext } from '../../../context/GlobalContext';
import { MAX_FILE_SIZE_IN_BYTES } from '../../../shared/constants';
import { triggerMaxFileSizeNotification, triggerDuplicateFilesNotification } from '../../../shared/utils';
import imageOutline from '../../../assets/image-outline.svg';
import './BaseDropzone.scss';

interface BaseDropzoneProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => void;
  acceptedTypes: string;
  removeHandler?: (fileId?: string) => void;
  detachHandler?: (fileId?: string) => void;
  showRemoveButton?: boolean;
  fileId?: string;
  selectOptions?: { value: string | number; label: string }[];
  onSelectChange?: (value: string | number) => void;
  deleteFileDisabled?: boolean;
  disabled?: boolean;
}

interface FileData {
  id: string;
  parentId: string;
  version: number;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deleted: boolean;
  lastAct: string;
  fileType: string;
  s3Key: string;
  fileUrl: string;
  name: string;
  displayName: string;
  extension: string;
  url: string;
}

const BaseDropzone: React.FC<BaseDropzoneProps> = ({
  onFileChange,
  acceptedTypes,
  removeHandler,
  detachHandler,
  showRemoveButton,
  fileId,
  selectOptions = [],
  onSelectChange,
  deleteFileDisabled = false,
  disabled
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileData, setFileData] = useState<FileData | null>(null);
  const [initialFileType, setInitialFileType] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { token } = useContext(InspectionContext);
  const { triggerGlobalNotification } = useContext(GlobalContext);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleClick = () => {
    if (disabled) return;
    fileInputRef.current?.click();
  };

  const handleRemove = () => {
    setShowModal(true);
  };

  const confirmRemove = () => {
    setInitialFileType(null);
    setShowModal(false);
    if (removeHandler) {
      removeHandler(fileId);
    }
  };

  const handleDetach = () => {
    setShowModal(false);
    if (detachHandler) {
      detachHandler(fileId);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (event.type === 'change') {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files[0]) {
        const file = target.files[0];
        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
          triggerMaxFileSizeNotification(triggerGlobalNotification, file.name);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          return;
        }
        const duplicateFiles = selectOptions.filter((option) => option.label === file.name);
        if (duplicateFiles.length > 0) {
          triggerDuplicateFilesNotification(triggerGlobalNotification);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          return;
        }
        setInitialFileType(file.type);
      }
    } else if (event.type === 'drop') {
      const e = event as React.DragEvent<HTMLDivElement>;
      e.preventDefault();
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
          triggerMaxFileSizeNotification(triggerGlobalNotification, file.name);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          return;
        }
        const duplicateFiles = selectOptions.filter((option) => option.label === file.name);
        if (duplicateFiles.length > 0) {
          triggerDuplicateFilesNotification(triggerGlobalNotification);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          return;
        }
        setInitialFileType(file.type);
      }
    }
    onFileChange(event);
  };

  const handleSelectChange = async (value: string | number) => {
    if (disabled) return;
    if (onSelectChange) {
      onSelectChange(value);
    }
    try {
      const data = await fetchFileUrl(value as string, token);
      setFileData(data);
      setInitialFileType(data.fileType);
    } catch (error) {
      console.error('Error fetching file data:', error);
    }
  };

  useEffect(() => {
    const getFileData = async () => {
      if (fileId) {
        try {
          const data = await fetchFileUrl(fileId, token);
          setFileData(data);
          setInitialFileType(data.fileType);
        } catch (error) {
          setFileData(null);
          console.error('Error fetching file data:', error);
        }
      }
    };
    if (fileId) {
      getFileData();
    }

    const channel = new BroadcastChannel('transaction-channel');
    channel.onmessage = (event) => {
      if (event.data.type === 'TRANSACTION_COMPLETED') {
        getFileData();
      }
    };
    return () => channel.close();
  }, [fileId, token]);

  const isVideo = (fileType: string) => {
    const videoTypes = ['video/mp4', 'video/avi', 'video/mov', 'video/wmv', 'video'];
    return videoTypes.includes(fileType);
  };

  const thumbs = () => {
    const previewUrl = fileData?.url;

    if (previewUrl && fileId) {
      const isVideoFile = isVideo(initialFileType ?? '') || (fileData && isVideo(fileData.fileType));

      if (isVideoFile) {
        return (
          <video src={previewUrl} className="file-preview" controls>
            <source src={previewUrl} type={initialFileType ?? fileData?.fileType ?? ''} />
            <track src="#" kind="captions" srcLang="en" label="English" default />
            Your browser does not support the video tag.
          </video>
        );
      }

      return <img src={previewUrl} alt="File preview" className="file-preview" />;
    }

    return (
      <div className="placeholder-container">
        <img src={imageOutline} alt="Upload Placeholder" className="upload-placeholder" />
        <p className="placeholder-text">Upload Media</p>
      </div>
    );
  };

  return (
    <div className="dropzone-container">
      <Dropdown
        value={fileData && fileId ? fileData.displayName : 'Select a file'}
        onSelect={(option) => handleSelectChange(option.value)}
        onSearchChange={setSearchValue}
        searchValue={searchValue}
        className="input__dropdown"
        options={selectOptions}
        placeholder="Select file"
        fixedWidth="100%"
        disabled={disabled}
      />
      <div
        className={`file-dropzone${disabled ? ' disabled' : ''}`}
        onDrop={handleFileChange}
        onDragOver={handleDragOver}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleClick();
          }
        }}
        role="button"
        tabIndex={disabled ? -1 : 0}
      >
        <input type="file" accept={acceptedTypes} onChange={handleFileChange} ref={fileInputRef} />
        {fileId && !fileData && (
          <div className="loader">
            <div className="loader-circle" />
          </div>
        )}
        {!fileId || fileData ? thumbs() : null}
      </div>
      {showRemoveButton && !disabled && (!fileId || fileData) && (
        <button className="file-removal-button" type="button" onClick={handleRemove}>
          Remove File
        </button>
      )}
      <DeleteConfirmationModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={confirmRemove}
        onRemoveFromSection={handleDetach}
        disabled={deleteFileDisabled}
      />
    </div>
  );
};

BaseDropzone.defaultProps = {
  removeHandler: undefined,
  detachHandler: undefined,
  showRemoveButton: false,
  fileId: '',
  selectOptions: [],
  onSelectChange: undefined,
  deleteFileDisabled: false,
  disabled: false
};

export default BaseDropzone;
