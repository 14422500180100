import {
  useContext, useState, useRef, useEffect
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Input, Dropdown, Button, FormLabel
} from '@flogistix/flo-ui';
import { IoRemoveCircle, IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

import LeakRepair from '../../../../../classes/leak-repair';
import { InspectionContext } from '../../../../../context/InspectionContext';
import AirMethaneFile, { UploadFile, PreviewFile } from '../../../../../classes/airmethane-file';
import Datepicker from '../../../../../components/Datepicker/Datepicker';
import ToggleCheck from '../../../../../components/ToggleCheck/ToggleCheck';
import BaseDropzone from '../../../../../components/FileDropzone/BaseDropzone/BaseDropzone';
import FileLabel from '../../../../../components/Shared/FileLabel/FileLabel';
import { fetchFilesForInspection } from '../../../../../services/airmethaneApi';
import { global } from '../../../../../shared/colors';
import '../leak-card-container.scss';
import './LeakRepairCard.scss';

const LeakRepairCard = ({
  leakRepair,
  deleteLeakRepair,
  leakTagOptions,
  repairNumber
}: {
  leakRepair: LeakRepair,
  deleteLeakRepair: () => void,
  leakTagOptions: string[],
  repairNumber: number
}) => {
  const {
    inspection, token, setCompletedSectionsOnChange, fileOptions, setFileOptions, hasFileId
  } = useContext(InspectionContext);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const [leakTagNumber, setLeakTagNumber] = useState(leakRepair.tagNumber || '');
  const [repairDate, setRepairDate] = useState(leakRepair.date ? new Date(leakRepair.date).toISOString() : '');
  const [repairFile, setRepairFile] = useState<PreviewFile | null>(leakRepair.file ?? null);
  const [fullyRepaired, setFullyRepaired] = useState(leakRepair.repaired ?? false);
  const [repairedBy, setRepairedBy] = useState(leakRepair.repairedBy ?? '');
  const [repairMethod, setRepairMethod] = useState(leakRepair.method ?? '');
  const [repairNotes, setRepairNotes] = useState(leakRepair.notes ?? '');
  const [searchTag, setSearchTag] = useState('');

  const handleRepairCreation = ({
    leakTag,
    additionalUpdates
  }: {
    leakTag?: string,
    additionalUpdates?: {
      pendingFiles?: UploadFile[],
      date?: Date,
      repaired?: boolean,
      repairedBy?: string,
      method?: string,
      notes?: string,
    }
  }) => {
    const associatedLeakTag = leakTag ?? leakRepair.tagNumber;
    const parentLeak = inspection!.leaks!.find((leak) => leak.tagNumber === associatedLeakTag);
    const repairToAdd = { ...leakRepair };
    delete repairToAdd.created;
    delete repairToAdd.tagNumber;
    if (!leakRepair.created) {
      parentLeak!.addRepair(
        token,
        inspection!,
        inspection!.flogistixId!,
        new LeakRepair({
          ...repairToAdd,
          ...additionalUpdates
        })
      );
      leakRepair.updateCreated(true);
    }
    leakRepair.updateTagNumber(leakTag ?? leakRepair.tagNumber!);
    setCompletedSectionsOnChange();
  };

  const handleTagNumberChange = (selectedOption: { value: string, label: string }) => {
    if (selectedOption.value === leakTagNumber) return;
    const newTagNumber = selectedOption.value;
    const newParentLeak = inspection!.leaks!.find((leak) => leak.tagNumber === newTagNumber);
    if (!leakRepair.created) {
      handleRepairCreation({
        leakTag: newTagNumber
      });
    } else {
      const repairToAdd = { ...leakRepair };
      delete repairToAdd.created;
      delete repairToAdd.tagNumber;
      const leakOfOldRepair = inspection!.leaks!.find((leak) => leak.tagNumber === leakRepair.tagNumber);
      leakOfOldRepair!.removeRepair(
        token,
        inspection!.flogistixId!,
        inspection!,
        leakRepair
      ).then(() => {
        newParentLeak!.addRepair(
          token,
          inspection!,
          inspection!.flogistixId!,
          repairToAdd as LeakRepair
        ).then(() => {
          setLeakTagNumber(newTagNumber);
          setCompletedSectionsOnChange();
        });
      });
      leakRepair.updateTagNumber(newTagNumber);
      setCompletedSectionsOnChange();
    }
  };

  const handleCompletionDateChange = (dateString: string) => {
    const inputDate = new Date(dateString);
    if (!Number.isNaN(inputDate.getTime())) {
      const adjustedDate = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000).toISOString();
      if (!leakRepair.created) {
        handleRepairCreation({
          additionalUpdates: {
            date: inputDate
          }
        });
      } else {
        leakRepair.patchLeakRepair(token, inspection!.flogistixId!, inspection!, {
          ...leakRepair,
          date: inputDate
        } as LeakRepair).then(() => {
          setRepairDate(adjustedDate);
          setCompletedSectionsOnChange();
        });
      }
    } else {
      setRepairDate('');
      setCompletedSectionsOnChange();
    }
  };

  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    const files = event.type === 'drop'
      ? (event as React.DragEvent<HTMLDivElement>).dataTransfer.files
      : (event as React.ChangeEvent<HTMLInputElement>).target.files;
    const file = files?.[0];
    if (!file) return;

    const fileObj: UploadFile = {
      id: uuidv4(),
      file,
      displayName: file.name
    };

    const newFile = new AirMethaneFile(fileObj.id, fileObj.displayName);

    await inspection!.addFiles(token, [fileObj]);
    await leakRepair.patchLeakRepair(token, inspection!.flogistixId!, inspection!, {
      ...leakRepair,
      file: newFile,
      pendingFiles: []
    } as unknown as LeakRepair);

    const blob = URL.createObjectURL(fileObj.file);
    setRepairFile({
      id: fileObj.id,
      previewUrl: blob,
      fileName: fileObj.displayName,
      fileType: fileObj.file.type
    } as PreviewFile);

    setCompletedSectionsOnChange();
  };

  const handleFileAttachment = async (fileId: string | number) => {
    const selectedFile = fileOptions.find((file: { value: string | number; }) => file.value === fileId);

    if (!leakRepair.created) {
      handleRepairCreation({
        additionalUpdates: {
          pendingFiles: []
        }
      });
    } else if (selectedFile) {
      try {
        const newFile = new AirMethaneFile(selectedFile.value as string, selectedFile.label);

        await leakRepair!.attachExistingFileForLeakRepair(token, inspection!.flogistixId!, inspection!, {
          ...leakRepair,
          leakRepairId: leakRepair.leakRepairId,
          file: newFile,
          pendingFiles: []
        } as unknown as LeakRepair);

        setRepairFile({
          id: selectedFile.value as string,
          previewUrl: selectedFile.label,
          fileName: selectedFile.label,
          fileType: ''
        } as PreviewFile);

        setCompletedSectionsOnChange();
      } catch (error) {
        console.error('Error updating location sign file:', error);
      }
    }
  };

  const removeRepairImage = (fileId: string | undefined) => {
    if (!fileId) return;

    const fileToRemove = new AirMethaneFile(fileId);

    leakRepair.removeFile(
      token,
      inspection!.flogistixId!,
      fileToRemove,
      0,
      inspection!.id!,
      inspection!
    ).then(() => {
      setRepairFile(null);
      setCompletedSectionsOnChange();
    });
  };

  const detachRepairImage = (fileId: string | undefined) => {
    if (!fileId) return;

    leakRepair.detachFile(
      token,
      inspection!.flogistixId!,
      inspection!.id!,
      inspection!
    ).then(() => {
      setRepairFile(null);
      setCompletedSectionsOnChange();
    });
  };

  const handleFullyRepairedChange = () => {
    const isFullyRepaired = !fullyRepaired;
    if (!leakRepair.created) {
      handleRepairCreation({
        additionalUpdates: {
          repaired: isFullyRepaired
        }
      });
    } else {
      leakRepair.patchLeakRepair(token, inspection!.flogistixId!, inspection!, {
        ...leakRepair,
        repaired: isFullyRepaired
      } as LeakRepair).then(() => {
        setFullyRepaired(isFullyRepaired);
        setCompletedSectionsOnChange();
      });
    }
    setFullyRepaired(isFullyRepaired);
  };

  const handleRepairedByChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const newRepairman = e.target.value;
    if (!leakRepair.created) {
      handleRepairCreation({
        additionalUpdates: {
          repairedBy: newRepairman
        }
      });
    } else {
      leakRepair.patchLeakRepair(token, inspection!.flogistixId!, inspection!, {
        ...leakRepair,
        repairedBy: newRepairman
      } as LeakRepair).then(() => {
        setRepairedBy(newRepairman);
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleRepairMethodChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const newMethod = e.target.value;
    if (!leakRepair.created) {
      handleRepairCreation({
        additionalUpdates: {
          method: newMethod
        }
      });
    } else {
      leakRepair.patchLeakRepair(token, inspection!.flogistixId!, inspection!, {
        ...leakRepair,
        method: newMethod
      } as LeakRepair).then(() => {
        setRepairMethod(newMethod);
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleRepairNotesChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const newNotes = e.target.value;
    if (!leakRepair.created) {
      handleRepairCreation({
        additionalUpdates: {
          notes: newNotes
        }
      });
    } else {
      leakRepair.patchLeakRepair(token, inspection!.flogistixId!, inspection!, {
        ...leakRepair,
        notes: newNotes
      } as LeakRepair).then(() => {
        setRepairNotes(newNotes);
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleRemoveClick = () => {
    setShowDeleteButton(true);
  };

  const handleDeleteClick = () => {
    deleteLeakRepair();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        deleteButtonRef.current
        && !deleteButtonRef.current.contains(event.target as Node)
      ) {
        setShowDeleteButton(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inspection?.id && token && fileOptions.length === 0) {
      (async () => {
        try {
          const files = await fetchFilesForInspection(inspection.id, token);
          const options = files.map((file: { id: string, name: string }) => ({
            label: file.name,
            value: file.id
          }));
          setFileOptions(options);
        } catch (error) {
          console.error('Error fetching inspection files:', error);
        }
      })();
    }
  }, [inspection?.id, token, fileOptions.length, setFileOptions]);

  const leakTagOptionsDropdown = leakTagOptions.map((tagNumber) => ({
    label: tagNumber,
    value: tagNumber
  }));

  return (
    <div className="delete-button-wrapper" ref={deleteButtonRef}>
      {showDeleteButton ? (
        <Button
          variation="red-outline"
          className="delete-button-container"
          size="medium"
          type="button"
          onClick={handleDeleteClick}
        >
          Delete
        </Button>
      ) : (
        <button
          className="icon-button-wrapper"
          type="button"
          onClick={handleRemoveClick}
          aria-label="Remove item"
        >
          <IoRemoveCircle className="remove-icon" size={24} />
        </button>
      )}
      <div className={`leak-card-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
        <div className="header-wrapper">
          <div className="repair-header">
            <h5 className="repair-header">{`Repair ${repairNumber}`}</h5>
            <button
              type="button"
              className="toggle-button"
              onClick={() => setIsExpanded(!isExpanded)}
              tabIndex={0}
            >
              {isExpanded ? (
                <IoChevronUpOutline style={{ color: global.Gray4 }} />
              ) : (
                <IoChevronDownOutline style={{ color: global.Gray4 }} />
              )}
            </button>
          </div>
        </div>
        {isExpanded && (
          <div className="fields-container">
            <FormLabel>Leak ID/Tag</FormLabel>
            <Dropdown
              id="leak-tag-dropdown"
              value={leakTagOptionsDropdown.find((option) => option.value === leakTagNumber)?.label || ''}
              onSelect={(option) => handleTagNumberChange(option as { value: string, label: string })}
              options={leakTagOptionsDropdown}
              onSearchChange={(value) => setSearchTag(value)}
              className="input__dropdown"
              searchValue={searchTag}
              placeholder="Select Leak Tag"
              fixedWidth="100%"
            />
            <FormLabel>Completion date</FormLabel>
            <Datepicker selected={repairDate || ''} onChange={handleCompletionDateChange} />
            <FileLabel acceptedTypes=".jpg, .png, or .heic">OGI image of repaired</FileLabel>
            <BaseDropzone
              onFileChange={handleFileSelection}
              acceptedTypes=".png, .jpg, .jpeg, .heic, .mp4, .mov, video, video/mp4"
              removeHandler={removeRepairImage}
              detachHandler={detachRepairImage}
              showRemoveButton={!!repairFile?.id}
              fileId={repairFile?.id}
              selectOptions={fileOptions.length > 0 ? fileOptions : undefined}
              onSelectChange={(value) => handleFileAttachment(value)}
              deleteFileDisabled={hasFileId(repairFile?.id as string)}
            />
            <div className="toggle-wrapper">
              <ToggleCheck isOn={fullyRepaired} handleToggle={handleFullyRepairedChange} />
              <FormLabel>Leak was fully repaired</FormLabel>
            </div>
            <FormLabel>Repaired by (optional)</FormLabel>
            <Input
              type="text"
              name="repairedBy"
              defaultValue={repairedBy}
              onBlur={handleRepairedByChange}
              placeholder="Enter the name of the person who repaired"
              className="input__text"
              fixedWidth="100%"
            />
            <FormLabel>Repaired method (optional)</FormLabel>
            <Input
              type="text"
              name="repairMethod"
              defaultValue={repairMethod}
              onBlur={handleRepairMethodChange}
              placeholder="Enter the repair method"
              className="input__text"
              fixedWidth="100%"
            />
            <FormLabel>Additional repair notes (optional)</FormLabel>
            <Input
              type="text"
              name="repairNotes"
              defaultValue={repairNotes}
              onBlur={handleRepairNotesChange}
              placeholder="Enter any additional repair notes"
              className="input__text"
              fixedWidth="100%"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LeakRepairCard;
