import React, { useContext, useEffect, useState } from 'react';
import {
  Header,
  Checkbox,
  Card,
  Toggle,
  Dropdown,
  DateRangePicker,
  DatePicker,
  Input,
  Button
} from '@flogistix/flo-ui';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalContext';
import { getAllInspectors } from '../../../dexie/operations';
import { DropdownOptionType } from '../../InspectionLayout/shared/typings';
import { formatDate } from '../../../shared/utils';
import { ReportData } from '../ScheduledReports/ScheduledReports';
import {
  immediateReportingPost,
  scheduledReportingPost,
  generateCronExpression,
  getReports,
  scheduledReportPatch
} from '../../../services/airMethaneReportingAPI';
import './CreateNewReport.scss';

interface AvailableReports {
  reportTitle: string;
  reportPk: string;
  reportDescription: string;
}

const generateTimeSlots = (): { label: string; value: string }[] => {
  const times = [];
  const padZero = (num: number) => num.toString().padStart(2, '0');

  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += 30) {
      const ampm = hour < 12 ? 'AM' : 'PM';
      const labelHour = hour % 12 === 0 ? 12 : hour % 12;
      const labelMinute = padZero(minute);
      const label = `${labelHour}:${labelMinute} ${ampm}`;
      const value = `${padZero(hour)}:${labelMinute}`;

      times.push({ label, value });
    }
  }
  return times;
};

const daySearchItems = [
  { label: 'Sunday', value: '0' },
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },
  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' }
];

const frequencySearchItems = [
  { label: 'Every Day', value: 'day' },
  { label: 'Every Week', value: 'week' },
  { label: 'Every Month', value: 'month' }
];

const cronToTimeDropdown = (cron: string) => {
  const [minute, hour] = cron.split(' ');
  const hourNum = Number(hour);

  let formattedHour;
  if (hourNum === 0) {
    formattedHour = 12;
  } else if (hourNum > 12) {
    formattedHour = hourNum - 12;
  } else {
    formattedHour = hourNum;
  }

  const period = hourNum < 12 ? 'AM' : 'PM';

  return {
    label: `${formattedHour}:${minute} ${period}`,
    value: `${hour}:${minute}`
  };
};

const cronToIntervalDropdown = (cron: string) => {
  const [, , dayOfMonth, month, dayOfWeek] = cron.split(' ');
  if (dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    return frequencySearchItems[0];
  }
  if (month === '*' && dayOfMonth === '*') {
    return frequencySearchItems[1];
  }
  return frequencySearchItems[2];
};

const cronToThisMonthDate = (cron: string) => {
  const now = new Date();
  const [, , dayOfMonth] = cron.split(' ');
  return new Date(now.getFullYear(), now.getMonth(), Number(dayOfMonth));
};

const CreateNewReport = ({ reportData }: { reportData: ReportData | undefined }) => {
  const {
    orgs, token, triggerGlobalNotification, loading
  } = useContext(GlobalContext);

  const now = new Date();
  const firstDayThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [reports, setReports] = useState<AvailableReports[]>([]);
  const [edit, setEdit] = useState<boolean>(reportData !== undefined);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [isOrgSet, setIsOrgSet] = useState<boolean>(!edit);
  const navigate = useNavigate();

  const [selectedReport, setSelectedReport] = useState<string>('');
  const [toggleOption, setToggleOption] = useState<string>('One-Time Report');
  const [orgSearchTerm, setOrgSearchTerm] = useState<string>('');
  const [orgValue, setOrgValue] = useState<DropdownOptionType | undefined>(undefined);
  const [siteOptions, setSiteOptions] = useState<DropdownOptionType[]>([]);
  const [siteSearchTerm, setSiteSearchTerm] = useState<string>('');
  const [siteValue, setSiteValue] = useState<DropdownOptionType | undefined>(undefined);
  const [inspectorSearchTerm, setInspectorSearchTerm] = useState<string>('');
  const [inspectorValue, setInspectorValue] = useState<DropdownOptionType | undefined>(undefined);
  const [inspectorOptions, setInspectorOptions] = useState<DropdownOptionType[]>([]);
  const [title, setTitle] = useState<string>('');
  const [frequencyValue, setFrequencyValue] = useState<DropdownOptionType | undefined>(undefined);
  const [dayValue, setDayValue] = useState<DropdownOptionType | undefined>(undefined);
  const [scheduledDate, setScheduledDate] = useState<Date | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const timeSearchItems = generateTimeSlots();
  const [time, setTime] = useState<DropdownOptionType | undefined>(undefined);

  const orgOptions: DropdownOptionType[] = orgs.map((org: { name: string, axilId: number }) => ({
    value: `${org.axilId}`,
    label: `${org.name}`
  }));

  const fetchInspectorOptions = async () => {
    const inspectors = await getAllInspectors();
    const formattedInspectors = inspectors.map((inspector) => ({
      value: `${inspector.inspectorId}`,
      label: `${inspector.name}`
    }));
    return formattedInspectors;
  };

  const fetchInspectorData = async () => {
    const options = await fetchInspectorOptions();
    setInspectorOptions(options);
  };

  const handleLeakReportSelected = (newSelectedReport: string) => {
    setSelectedReport(newSelectedReport);
  };

  const isDisabled = () => {
    const isOneTimeReportValid = (selectedReport && toggleOption === 'One-Time Report'
      && startDate && endDate && orgValue);

    const isScheduledReportValid = (
      orgValue
    ) && (
      (frequencyValue?.value === 'day' && time && title)
        || (frequencyValue?.value === 'week' && dayValue && time && title)
        || (frequencyValue?.value === 'month' && scheduledDate && time && title)
    );

    return !(isOneTimeReportValid || isScheduledReportValid);
  };

  const buildOneTimeReportData = () => ({
    orgId: orgValue?.value ? parseInt(orgValue?.value.toString(), 10) : 0,
    siteId: siteValue?.value.toString() ?? 'All',
    inspectorId: inspectorValue?.value.toString() ?? 'All',
    startTime: formatDate(startDate),
    endTime: formatDate(endDate?.setDate(endDate.getDate() + 1))
  });

  const buildScheduledReportData = () => ({
    userReportName: reportData ? reportData.report.userReportName : title,
    reportName: selectedReport,
    cronUtc: generateCronExpression(
      frequencyValue?.value.toString() ?? 'day',
      time?.value.toString() ?? '00:00',
      dayValue?.value.toString(),
      scheduledDate
    ),
    options: {
      orgId: orgValue?.value ? parseInt(orgValue?.value.toString(), 10) : 0,
      siteId: siteValue?.value ? siteValue?.value.toString() : 'All',
      inspectorId: inspectorValue?.value ? inspectorValue?.value.toString() : 'All',
      reportName: title
    }
  });

  const triggerSuccessNotification = () => {
    if (edit) {
      triggerGlobalNotification({
        type: 'success',
        message: 'Your report was successfully updated.',
        title: 'Successfully updated report',
        fixedWidth: '70%'
      });
    } else {
      triggerGlobalNotification({
        type: 'success',
        message: 'Your report was successfully created and downloaded..',
        title: 'Successfully scheduled report',
        fixedWidth: '70%'
      });
    }
  };

  const triggerErrorNotification = (error: Error) => {
    if (edit) {
      triggerGlobalNotification({
        type: 'error',
        message: `There was a problem updating your report ${error.message}`,
        title: 'Report update failed',
        fixedWidth: '60%'
      });
    } else {
      triggerGlobalNotification({
        type: 'error',
        message: `There was a problem generating your report ${error.message}`,
        title: 'Report failed',
        fixedWidth: '60%'
      });
    }
  };

  const updateReport = async () => {
    setButtonLoading(true);
    if (!selectedReport) { return; }
    if (frequencyValue && time) {
      const data = buildScheduledReportData();
      scheduledReportPatch(data, token)
        .then(() => {
          setButtonLoading(false);
          triggerSuccessNotification();
          setSelectedReport('');
          navigate('/reports/scheduled-reports');
        }).catch((error) => {
          setButtonLoading(false);
          triggerErrorNotification(error);
        });
    }
  };

  const generateReport = async () => {
    setButtonLoading(true);
    if (!selectedReport) { return; }
    if (toggleOption === 'One-Time Report') {
      const data = buildOneTimeReportData();
      immediateReportingPost(data, token, selectedReport)
        .then(() => {
          setButtonLoading(false);
          triggerSuccessNotification();
          setSelectedReport('');
        }).catch((error) => {
          setButtonLoading(false);
          triggerErrorNotification(error);
        });
    } else if (toggleOption === 'Scheduled Report' && frequencyValue && time) {
      const data = buildScheduledReportData();
      scheduledReportingPost(data, token)
        .then(() => {
          setButtonLoading(false);
          triggerSuccessNotification();
          setSelectedReport('');
        }).catch((error) => {
          setButtonLoading(false);
          triggerErrorNotification(error);
        });
    }
  };

  useEffect(() => {
    fetchInspectorData();
  }, []);

  useEffect(() => {
    const fetchScheduledReports = async () => {
      try {
        const response = await getReports(token);
        const availableReports = response.reports.map((
          availableReport: { title: string; pk: string; description: string; }
        ) => (
          {
            reportTitle: availableReport.title,
            reportPk: availableReport.pk,
            reportDescription: availableReport.description
          }));
        setReports(availableReports);
        setPageLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchScheduledReports();
  }, [token]);

  useEffect(() => {
    if (reportData) {
      setSelectedReport(reportData.report.reportName);
      setOrgValue({
        value: reportData.report.options.orgId,
        label: reportData.orgName
      });
      setToggleOption('Scheduled Report');
      setTitle(reportData.report.options.reportName);
      setTime(cronToTimeDropdown(reportData.report.cronUtc));
      setFrequencyValue(cronToIntervalDropdown(reportData.report.cronUtc));
      setScheduledDate(cronToThisMonthDate(reportData.report.cronUtc));
      setInspectorValue({ value: reportData.report.options.inspectorId, label: reportData.inspectorName });
      setSiteValue({ value: reportData.report.options.siteId, label: reportData.siteName });
      setDayValue(daySearchItems.find((day) => day.value === reportData.report.cronUtc.split(' ')[4]));
    }
  }, [reportData]);

  useEffect(() => {
    if (!edit) {
      setStartDate(null);
      setEndDate(null);
      setDayValue(undefined);
      setTime(undefined);
      setFrequencyValue(undefined);
      setTitle('');
      setOrgValue(undefined);
      setSiteValue(undefined);
      setInspectorValue(undefined);
    }
  }, [selectedReport]);

  useEffect(() => {
    const sitesOnOrg = orgs.find(
      (org: { name: string, id: string, axilId: number }) => (org?.axilId?.toString() === orgValue?.value?.toString())
    )?.sites;
    const formattedSiteOptions = sitesOnOrg?.map((siteOnOrg: { id: string, name: string }) => ({
      value: `${siteOnOrg.id}`,
      label: `${siteOnOrg.name}`
    }));
    setSiteOptions(formattedSiteOptions || []);
    if (isOrgSet) { setSiteValue(undefined); }
    if (!isOrgSet && orgValue) { setIsOrgSet(true); }
  }, [orgValue]);

  return (
    <div className="report-container">
      {pageLoading || loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="">
            <div className="label-container--row margin-bottom-8">
              <Header className="label" headerSize="h6">Choose Report Type</Header>
              <Header className="label red" headerSize="h6"> * </Header>
            </div>
            <div className="content-container">
              {reports.map((availableReport: AvailableReports) => (
                <Card
                  onCardClick={() => { handleLeakReportSelected(availableReport.reportPk); }}
                  active={selectedReport === availableReport.reportPk}
                >
                  <Checkbox
                    title={availableReport.reportTitle}
                    subTitle={availableReport.reportDescription}
                    onChecked={() => { handleLeakReportSelected(availableReport.reportPk); }}
                    checked={selectedReport === availableReport.reportPk}
                  />
                </Card>
              ))}
            </div>
          </div>
          {selectedReport && !edit && (
            <Toggle
              firstOption="One-Time Report"
              secondOption="Scheduled Report"
              activeOption={toggleOption}
              onToggle={() => setToggleOption(
                toggleOption === 'One-Time Report' ? 'Scheduled Report' : 'One-Time Report'
              )}
            />
          )}
          {selectedReport && (
            <div className="content-container">
              <div>
                <div className="label-container--row">
                  <Header className="input-label" headerSize="h6">Organization</Header>
                  <Header className="label red" headerSize="h6"> * </Header>
                </div>
                <Dropdown
                  id="org-dropdown"
                  onSelect={setOrgValue}
                  placeholder="Select Organization"
                  value={orgValue?.label ?? ''}
                  options={orgOptions.filter(
                    (option) => !orgSearchTerm || option.label.toLowerCase().includes(orgSearchTerm.toLowerCase())
                  )}
                  searchValue={orgSearchTerm}
                  onSearchChange={(value) => { setOrgSearchTerm(value); }}
                  onClear={() => setOrgValue(undefined)}
                  fixedWidth="320px"
                />
              </div>
              <div>
                <Header className="input-label" headerSize="h6">Site</Header>
                <Dropdown
                  id="site-dropdown"
                  onSelect={setSiteValue}
                  placeholder="Select Site"
                  value={siteValue?.label ?? ''}
                  options={
                    siteOptions.filter((option) => option.label.toLowerCase().includes(siteSearchTerm.toLowerCase()))
                  }
                  searchValue={siteSearchTerm}
                  onSearchChange={(value) => { setSiteSearchTerm(value); }}
                  onClear={() => setSiteValue(undefined)}
                  fixedWidth="320px"
                />
              </div>
              <div>
                <Header className="input-label" headerSize="h6">Inspector</Header>
                <Dropdown
                  id="inspector-dropdown"
                  onSelect={(option) => setInspectorValue(option)}
                  placeholder="Select Inspector"
                  value={inspectorValue?.label ?? ''}
                  options={inspectorOptions.filter(
                    (option) => option.label.toLowerCase().includes(inspectorSearchTerm.toLowerCase())
                  )}
                  searchValue={inspectorSearchTerm}
                  onSearchChange={(value) => { setInspectorSearchTerm(value); }}
                  onClear={() => setInspectorValue(undefined)}
                  fixedWidth="320px"
                />
              </div>
            </div>
          )}
          {(selectedReport && toggleOption === 'Scheduled Report') && (
            <div className="content-container">
              <div>
                <div className="label-container--row">
                  <Header className="input-label" headerSize="h6">Title</Header>
                  <Header className="label red" headerSize="h6"> * </Header>
                </div>
                <Input
                  placeholder="My report title"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                  fixedWidth="320px"
                />
              </div>
              <div>
                <div className="label-container--row">
                  <Header className="input-label" headerSize="h6">Frequency</Header>
                  <Header className="label red" headerSize="h6"> * </Header>
                </div>
                <Dropdown
                  id="frequency-dropdown"
                  onSelect={(option) => setFrequencyValue(option)}
                  placeholder="Select Frequency"
                  value={frequencyValue?.label ?? ''}
                  options={frequencySearchItems}
                  fixedWidth="320px"
                />
              </div>
              {frequencyValue?.value !== 'day' && (frequencyValue?.value === 'week' ? (
                <div>
                  <div className="label-container--row">
                    <Header className="input-label" headerSize="h6">Start Date</Header>
                    <Header className="label red" headerSize="h6"> * </Header>
                  </div>
                  <Dropdown
                    id="start-date-dropdown"
                    onSelect={(option) => setDayValue(option)}
                    placeholder="Select Start Date"
                    value={dayValue?.label ?? ''}
                    options={daySearchItems}
                    fixedWidth="320px"
                  />
                </div>
              ) : (
                <div>
                  <div className="label-container--row">
                    <Header className="input-label" headerSize="h6">Scheduled Date</Header>
                    <Header className="label red" headerSize="h6"> * </Header>
                  </div>
                  <DatePicker
                    selectedDate={scheduledDate || null}
                    setSelectedDate={(date) => setScheduledDate(date || undefined)}
                    minDate={firstDayThisMonth}
                    maxDate={lastDayThisMonth}
                    allowedMonthRange={{ startDate: 1, endDate: 28 }}
                  />
                </div>
              )
              )}
              <div>
                <div className="label-container--row">
                  <Header className="input-label" headerSize="h6">Time</Header>
                  <Header className="label red" headerSize="h6"> * </Header>
                </div>
                <Dropdown
                  id="time-dropdown"
                  onSelect={(option) => setTime(option)}
                  placeholder="Select Time"
                  value={time?.label ?? ''}
                  options={timeSearchItems}
                  fixedWidth="320px"
                />
              </div>
            </div>
          )}
          {(toggleOption === 'One-Time Report') && selectedReport && (
            <div className="date-container">
              <div className="label-container">
                <div className="label-container--row">
                  <Header className="label" headerSize="h6">Date Range</Header>
                  <Header className="label red" headerSize="h6"> * </Header>
                </div>
                <Header className="label--description" headerSize="h6">The range of collected data to report.</Header>
              </div>
              <DateRangePicker
                selectedStartDate={startDate}
                selectedEndDate={endDate}
                setSelectedEndDate={(date) => setEndDate(date)}
                setSelectedStartDate={(date) => setStartDate(date)}
                variant="dateRange"
              />
            </div>
          )}
          <div className="generate-button-container">
            {edit
              ? (
                <>
                  <Button
                    className="generate-button"
                    onClick={() => { setEdit(false); navigate('/reports/scheduled-reports'); }}
                    fixedWidth="121px"
                    variation="shadow-outline"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="generate-button"
                    onClick={() => { updateReport(); }}
                    variation="blue"
                    fixedWidth="121px"
                    disabled={isDisabled() || buttonLoading}
                    loading={buttonLoading}
                  >
                    Update
                  </Button>
                </>
              ) : (
                <Button
                  className="generate-button"
                  onClick={() => { generateReport(); }}
                  disabled={isDisabled() || buttonLoading}
                  fixedWidth="121px"
                  loading={buttonLoading}
                >
                  Generate
                </Button>
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default CreateNewReport;
