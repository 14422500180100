import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from '@flogistix/flo-ui';
import { InspectionContext } from '../../../../context/InspectionContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { Inspection } from '../../../../classes/inspection';
import './FinalSubmission.scss';

const FinalSubmission = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { inspection, token } = useContext(InspectionContext);
  const { inspections, setInspections } = useContext(GlobalContext);

  const completedStatus = {
    code: 'complete',
    comment: 'Form is complete and ready for customer viewing',
    display: 'Submitted to customer'
  };

  const handleSubmission = () => {
    setIsSubmitting(true);
    inspection!.submitInspection(token);
    const newInspections = inspections.map((i: Inspection) => {
      if (i.id === inspection?.id) {
        return {
          ...i,
          status: completedStatus
        };
      }
      return i;
    });
    setInspections(newInspections);
    navigate('/');
    setIsSubmitting(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="final-submission">
      <h1 className="submit-title">Before you submit</h1>
      <p className="submit-subtext">Make sure you have everything ready</p>
      <br />
      <br />
      <h4 className="confirm-title">I understand...</h4>
      <p className="confirm-text">
        I understand that once I submit this form, I will not be able to make
        any changes or adjustments to the content or inspection created. I also
        confirm that all the documents and images included in this form meet
        the criteria for inspection established by the relevant state and
        federal regulations. I am aware that I will be held accountable if the
        documents and images fail to meet these standards. Therefore, I take
        full responsibility for the accuracy and completeness of the submission
        I am providing.
      </p>
      <br />
      <Button
        variation="blue"
        type="button"
        disabled={isSubmitting}
        onClick={handleOpenModal}
        fixedWidth="220px"
        loading={isSubmitting}
      >
        Submit inspection
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Are you sure?"
        subtitle="Submitting this inspection will finalize all information you have entered."
        actions={{
          onCancel: handleCloseModal,
          onConfirm: handleSubmission,
          loading: isSubmitting
        }}
        variant="confirm"
        className="submission-modal"
      />
    </div>
  );
};

export default FinalSubmission;
