import React, { useState, useCallback, useContext } from 'react';
import InputMask from 'react-input-mask';
import './rangePicker.scss';
import { InspectionContext } from '../../context/InspectionContext';

const RangerPicker: React.FC = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext) || {};

  const initialStart = inspection?.startTime ? inspection.startTime.slice(0, 5) : '';
  const initialEnd = inspection?.endTime ? inspection.endTime.slice(0, 5) : '';

  const [localStartTime, setLocalStartTime] = useState<string>(initialStart);
  const [localEndTime, setLocalEndTime] = useState<string>(initialEnd);
  const [startError, setStartError] = useState<boolean>(false);
  const [endError, setEndError] = useState<boolean>(false);

  const validTime = (hh: string, mm: string, isStart: boolean) => {
    const isInvalidTime = !hh || !mm || hh > '23' || mm > '59';

    if (isInvalidTime) {
      if (isStart) {
        setStartError(true);
      } else {
        setEndError(true);
      }
      return false;
    }

    if (isStart) {
      setStartError(false);
    } else {
      setEndError(false);
    }
    return true;
  };

  const handleTimeBlur = useCallback(
    async (isStart: boolean, value: string) => {
      const trimmedValue = value.trim();

      if (!trimmedValue) {
        if (inspection && token) {
          try {
            if (isStart) {
              setLocalStartTime('');
              await inspection.removeStartTime(token);
            } else {
              setLocalEndTime('');
              await inspection.removeEndTime(token);
            }
            setCompletedSectionsOnChange();
          } catch (error) {
            console.error(isStart ? 'Error removing start time:' : 'Error removing end time:', error);
          }
        }
        return;
      }

      const [hh, mm] = trimmedValue.split(':').map((part) => part.padStart(2, '0'));
      const finalValue = `${hh}:${mm}`;
      const finalValueWithSeconds = `${finalValue}:00`;

      if (isStart) {
        setLocalStartTime(finalValue);
      } else {
        setLocalEndTime(finalValue);
      }

      if (!validTime(hh, mm, isStart)) {
        return;
      }

      if (inspection && token) {
        try {
          if (isStart && inspection.startTime !== finalValueWithSeconds) {
            await inspection.updateStartTime(token, finalValueWithSeconds);
          } else if (!isStart && inspection.endTime !== finalValueWithSeconds) {
            await inspection.updateEndTime(token, finalValueWithSeconds);
          }
          setCompletedSectionsOnChange();
        } catch (error) {
          console.error(isStart ? 'Error updating start time:' : 'Error updating end time:', error);
        }
      }
    },
    [inspection, token, setCompletedSectionsOnChange]
  );

  return (
    <div className="time-picker-container">
      <div className="time-picker-icon">
        <InputMask
          mask="99:99"
          value={localStartTime}
          onChange={(e) => setLocalStartTime(e.target.value)}
          onBlur={() => handleTimeBlur(true, localStartTime)}
          className={`time-picker${startError ? ' error' : ''}`}
          placeholder="HH:MM"
          maskChar={null}
        >
          {(inputProps) => <input {...inputProps} />}
        </InputMask>
      </div>

      <div className="time-picker-icon time-picker-icon-end">
        <InputMask
          mask="99:99"
          value={localEndTime}
          onChange={(e) => setLocalEndTime(e.target.value)}
          onBlur={() => handleTimeBlur(false, localEndTime)}
          className={`time-picker${endError ? ' error' : ''}`}
          placeholder="HH:MM"
          maskChar={null}
        >
          {(inputProps) => <input {...inputProps} />}
        </InputMask>
      </div>
    </div>
  );
};

export default RangerPicker;
