import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Toast } from '@flogistix/flo-ui';

import './Notification.scss';
import { GlobalContext } from '../../context/GlobalContext';
import { INFINITE } from '../../shared/constants';

const Notification = () => {
  const { globalNotificationType, closeGlobalNotification } = useContext(GlobalContext);
  const {
    show,
    message,
    type,
    duration,
    customTitle,
    fixedWidth
  } = globalNotificationType;

  useEffect(() => {
    let timer;
    if (show && duration !== INFINITE) {
      timer = setTimeout(() => {
        closeGlobalNotification();
      }, duration);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [show, closeGlobalNotification, duration]);

  const title = customTitle || (type.charAt(0).toUpperCase() + type.slice(1));

  return (
    <aside className={`notification ${show ? 'notification-enter' : 'notification-exit'}`}>
      {show ? (
        <Toast
          handleClose={closeGlobalNotification}
          isOpen={show}
          message={message}
          title={title}
          type={type}
          width={fixedWidth}
          timeout={duration}
          className="flo-toast"
        />
      ) : (null)}
    </aside>
  );
};

Notification.propTypes = {
  globalNotificationType: PropTypes.shape({
    show: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.oneOf(['error', 'success', 'warning']),
    fixedWidth: PropTypes.string
  })
};

Notification.defaultProps = {
  globalNotificationType: {
    show: false,
    message: '',
    type: 'error',
    fixedWidth: ''
  }
};

export default Notification;
