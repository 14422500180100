import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from '@flogistix/flo-ui';

import './EmptyState.scss';

const EmptyState = () => {
  const navigate = useNavigate();

  const handleCreateInspection = () => {
    navigate('/inspections');
  };

  return (
    <div className="empty-state">
      <div className="empty-state__icon-container">
        <Icon iconName="File" />
      </div>
      <h3 className="empty-state__header">No files found</h3>
      <p className="empty-state__subheader">
        No files here yet. Create an inspection or switch tabs to explore other content.
      </p>
      <div className="empty-state__button">
        <Button
          type="button"
          data-cy="CreateInspection"
          onClick={handleCreateInspection}
        >
          <span className="empty-state__button-icon">
            <Icon iconName="Plus" height={20} width={20} />
          </span>
          Create inspection
        </Button>
      </div>
    </div>
  );
};

export default EmptyState;
