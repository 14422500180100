import {
  useContext, useState, useEffect, SetStateAction
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IoAddOutline } from 'react-icons/io5';
import { Toggle, Input, FormLabel } from '@flogistix/flo-ui';
import { InspectionContext } from '../../../../context/InspectionContext';
import MonitoredComponent from '../../../../classes/monitored-component';
import MonitoredComponentCard from './MonitoredComponentCard';

const MonitoredComponents = () => {
  const { inspection, token } = useContext(InspectionContext);
  const [monitoredComponents, setMonitoredComponents] = useState(inspection!.monitoredComponents
    || [new MonitoredComponent({ monitoredComponentId: uuidv4() })]);
  const [showTextInput, setShowTextInput] = useState(false);
  const [deviationReason, setDeviationReason] = useState(inspection?.monitoringPlanDeviations
    || '');

  const createMonitoredComponent = () => {
    const newComponent = new MonitoredComponent({
      monitoredComponentId: uuidv4()
    });

    setMonitoredComponents([...monitoredComponents, newComponent]);

    // don't add a transaction here, the componentType is undefined & will fail
  };

  const removeMonitoredComponent = (component: MonitoredComponent) => {
    setMonitoredComponents(
      (monitoredComponents ?? []).filter((c) => c.monitoredComponentId !== component.monitoredComponentId)
    );
    if (component.componentType) inspection!.removeMonitoredComponent(token, component);
  };

  const handleToggle = () => {
    if (!deviationReason) {
      setShowTextInput((prevState) => !prevState);
    }
  };

  const handleDeviationBlur = async () => {
    if (deviationReason === '' && inspection?.monitoringPlanDeviations) {
      await inspection!.removeMonitoringPlanDeviations(token);
    } else if (deviationReason !== inspection?.monitoringPlanDeviations) {
      inspection!.updateMonitoringPlanDeviations(token, deviationReason);
    }
  };

  useEffect(() => {
    if (deviationReason) {
      setShowTextInput(true);
    }
    if (monitoredComponents.length === 0) {
      setMonitoredComponents([new MonitoredComponent({ monitoredComponentId: uuidv4() })]);
    }
  }, [deviationReason]);

  return (
    <div>
      <div className="section-heading">
        <h1 className="page-details__header">Monitoring details</h1>
        <h4 className="part">Part 2 of 2</h4>
        <button
          className="add-new-item-button"
          type="button"
          onClick={createMonitoredComponent}
        >
          <IoAddOutline />
          Add new component
        </button>
      </div>

      {monitoredComponents.map((c, i) => (
        <MonitoredComponentCard
          key={c.monitoredComponentId}
          component={c}
          componentIndex={i}
          removeMonitoredComponent={(newComponent: MonitoredComponent) => removeMonitoredComponent(newComponent)}
        />
      ))}

      <div className="section-footer">
        <button
          className="add-new-item-button-bottom"
          type="button"
          onClick={createMonitoredComponent}
        >
          <IoAddOutline />
          Add new component
        </button>
        <FormLabel>Any deviations from the monitoring plan?</FormLabel>
        <Toggle
          firstOption="Yes"
          secondOption="No"
          activeOption={showTextInput ? 'Yes' : 'No'}
          onToggle={handleToggle}
          disabled={!!deviationReason}
        />
        {showTextInput && (
          <div className="deviation-container">
            <FormLabel>What is the reason for the deviation?</FormLabel>
            <div className="deviation-container-input">
              <Input
                type="text"
                id="deviationReason"
                value={deviationReason}
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setDeviationReason(e.target.value)}
                onBlur={handleDeviationBlur}
                placeholder="Enter reason"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonitoredComponents;
