import { FormLabel } from '@flogistix/flo-ui';
import { READABLE_MAX_FILE_SIZE } from '../../../shared/constants';

const FileLabel = ({
  acceptedTypes,
  children
}: {
  acceptedTypes: string,
  children?: React.ReactNode
}) => {
  const subLabel = `Files should be ${acceptedTypes} (max. ${READABLE_MAX_FILE_SIZE})`;

  return (
    <FormLabel subLabel={subLabel}>{children}</FormLabel>
  );
};

FileLabel.defaultProps = {
  children: undefined
};

export default FileLabel;
