/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { useLocation, NavLink, matchPath } from 'react-router-dom';
import {
  IoAdd,
  IoAddOutline,
  IoMap,
  IoMapOutline
} from 'react-icons/io5';
import {
  barChart,
  barChartOutline,
  upload,
  uploadOutline,
  clipboard,
  clipboardOutline
} from './Icons';

import { GlobalContext } from '../../context/GlobalContext';
import { StyledAntMenu, StyledSider } from './styled-components';
import { homeSections } from '../../shared/constants';

const Importer = upload;
const ImporterOutline = uploadOutline;

const icons = {
  clipboard,
  clipboardOutline,
  IoAdd,
  IoAddOutline,
  upload,
  uploadOutline,
  Importer,
  ImporterOutline,
  IoMap,
  IoMapOutline,
  barChart,
  barChartOutline
};

const generateNavIcon = (iconName, isActive, linkedIcon) => {
  const Icon = icons[`${iconName}${isActive ? '' : 'Outline'}`];
  const isImporter = linkedIcon.key === 'Importer';
  return <Icon className={isImporter ? 'rotate' : ''} aria-hidden="true" />;
};

const buildItemLinks = (
  navItems,
  currentActiveKey,
  toggleSideNav,
  setActiveHomeSection
) => navItems.map((linkItem) => ({
  ...linkItem,
  icon: (
    <NavLink
      exact="true"
      to={linkItem.path}
      key={linkItem.key}
      onClick={() => {
        if (linkItem.icon === 'IoAdd' || linkItem.icon === 'IoAddOutline') {
          toggleSideNav();
        }
        if (linkItem.key === 'Inspections') setActiveHomeSection(homeSections.IN_PROGRESS);
      }}
    >
      {generateNavIcon(
        linkItem.icon,
        currentActiveKey === linkItem.key,
        linkItem,
        linkItem.key
      )}
    </NavLink>
  )
}));

const SideNav = () => {
  const {
    is_admin,
    has_access,
    sideNavVisible,
    toggleSideNav,
    setActiveHomeSection
  } = useContext(GlobalContext);

  const defaultLinks = [
    {
      key: 'Inspections',
      icon: 'clipboard',
      label: 'Inspections',
      path: '/'
    }
  ];

  const navLinks = [
    {
      key: 'Inspections',
      icon: 'clipboard',
      label: 'Inspections',
      path: '/'
    },
    {
      key: 'Reports',
      id: 'NavReports',
      icon: 'barChart',
      label: 'Reports',
      path: '/reports'
    },
    {
      key: 'MultiUpload',
      id: 'NavMultiUpload',
      icon: 'upload',
      label: 'Transactions Table',
      path: '/upload-management'
    }
  ];

  const { pathname } = useLocation();

  const activeKey = navLinks.find((link) => matchPath(`${link.path}/*`, pathname))?.key || 'Inspections';

  const displayedLinks = is_admin ? navLinks : defaultLinks;

  return !has_access || !sideNavVisible ? null : (
    <StyledSider collapsedWidth={62} collapsed>
      {is_admin && (
        <nav aria-label="sidebar">
          <StyledAntMenu
            mode="inline"
            items={buildItemLinks(
              displayedLinks,
              activeKey,
              toggleSideNav,
              setActiveHomeSection
            )}
            selectedKeys={[
              activeKey
            ]}
          />
        </nav>
      )}
    </StyledSider>
  );
};

export default SideNav;
