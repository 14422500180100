import React, {
  useState, useEffect, useContext, useRef
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { SearchBar } from '@flogistix/flo-ui';
import { format } from 'date-fns';

import { GlobalContext } from '../../context/GlobalContext';
import { InspectionContext } from '../../context/InspectionContext';
import TableTabs from './TableTabs';
import { getAllKeys, getValueByPath } from './utils';
import { global } from '../../shared/colors';

const rowHeight = 50;

const CommonStyles = `
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: ${global.Gray6};
    border-radius: 20px;
`;

const StyledWrapper = styled.div`
    ${CommonStyles}
`;

const StyledEmptyState = styled(StyledWrapper)`
    h1 {
        font-weight: 600;
        font-size: 32px;
        color: ${global.Black};
        margin-top: 90px;
        margin-bottom: 32px;
    }
    p {
        font-weight: 400;
        font-size: 24px;
        color: ${global.Gray3};
    }
`;

const StyledEmptyFiles = styled(StyledWrapper)`
    width: 100%;

    h1 {
        font-size: 24px;
        font-weight: 600;
        color: black;
        margin-bottom: 28px;
    }

    p {
        margin: 0 auto;
        font-size: 20px;
        color: ${global.Gray4};
        font-weight: 400;
    }
`;

const StyledTable = styled.table`
    height: 450px;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    box-sizing: none;
    border-radius: 10px;
    thead {
        z-index: 11;
        border-radius: 10px;
        position: sticky;
        background: ${global.White};
        top: 0;
    }
    tr {
        border-radius: 10px;
        height: ${rowHeight}px;
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    &::-webkit-scrollbar {
        display: none;
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
        margin-top: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${global.Gray6};
        border-radius: 2px;
    }
`;

const StyledTh = styled.th`
    overflow: hidden;
    height:${rowHeight}px;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: ${global.White};
    padding: 10px;
    text-align: left;
    width: ${(props) => props.width || 'auto'};
    cursor: ${(props) => (props.isSortable ? 'pointer' : 'default')};
    height: 31px;
    &::hover {
        background-color: ${global.HighlightGrey};
    }
    &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

const StyledTr = styled.tr`
    background: ${global.Gray};
    height: ${rowHeight}px;
    &:nth-child(even) {
        background: ${global.White};
    }
    &:hover {
        background-color: ${global.Gray5};
        cursor: pointer;
    }
`;

const StyledTd = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${(props) => props.width || 'auto'};
  padding: 10px;
  padding-left: ${({ checkbox }) => (checkbox ? '45px' : '10px')};
`;

const ArrowContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    svg:first-child {
        margin-bottom: -8px;
    }
`;

const InnerHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 28px;
`;

const StyledDiv = styled.div`
    background: ${global.Gray6};
    padding: ${(props) => (props.tabsEnabled ? '20px' : '50px 40px 40px 40px')};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
`;

const CheckboxTd = styled(StyledTd)`
    min-width: 50px;
    text-align: center;
    padding: 10px;
    overflow: visible;

    label.checkbox-group {
        display: inline-flex;
        align-items: center;
    }

    input[type='checkbox'] {
        margin: 0;
        cursor: pointer;
        visibility: hidden;
        width: 16px;
        height: 16px;
        position: absolute;
    }
    .custom-checkbox {
        width: 16px;
        height: 16px;
        background-color: ${global.White};
        border: 1px solid ${global.Gray5};
        border-radius: 5px;
        position: relative;
        margin-left: 4px;
        margin-top: -2px;
    }
    .checkbox-group input:checked ~ .custom-checkbox {
        background-color: rgba(10, 132, 255, 0.15);
        border-color: ${global.PrimaryBlue};
    }
    .custom-checkbox:after {
        content: '';
        display: none;
    }
    .checkbox-group input:checked ~ .custom-checkbox:after {
        display: block;
    }
    .checkbox-group .custom-checkbox:after {
        position: absolute;
        top: 3px;
        left: 5px;
        width: 2px;
        height: 6px;
        border: solid ${global.PrimaryBlue};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;

const StyledSection = styled.section`
    height: 98%;
    .search-bar-container {
        margin-bottom: 20px;
        font-size: 14px;
    }
`;

const AscIcon = ({ isActive }) => (
  <TiArrowSortedUp size={16} color={isActive ? global.Black : global.Gray5} />
);

const DescIcon = ({ isActive }) => (
  <TiArrowSortedDown
    size={16}
    color={isActive ? global.Black : global.Gray5}
  />
);

const NeutralIcon = () => (
  <ArrowContainer>
    <AscIcon isActive={false} />
    <DescIcon isActive={false} />
  </ArrowContainer>
);

const Table = ({
  data: initialTableData,
  columns: tableColumns,
  completedColumns,
  onRow,
  checkbox,
  enableTabs,
  isUpload,
  searchable,
  searchPlaceholder,
  selectedKeys = [],
  onRowSelectionChange = () => null
}) => {
  const params = useParams();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const [tableData, setTableData] = useState(initialTableData);
  const [sortState, setSortState] = useState({
    key: null,
    direction: null
  });
  const [selectedRows, setSelectedRows] = useState([]);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { inspections, is_admin } = useContext(GlobalContext);
  const { locationData, setLocationData } = useContext(InspectionContext);

  const internalChangeRef = useRef(false);

  const setLocationToMatchOrgParam = (locations_with_inspections) => {
    const matchingLocations = locations_with_inspections?.filter(
      (element) => element?.org?.id.toString() === customerId.toString()
    );

    setLocationData(matchingLocations);
  };

  const filteredInspections = customerId
    ? inspections?.filter(
      (inspection) => inspection.org.id.toString() === customerId
    )
    : inspections;

  const handleInProgress = (record) => {
    navigate(`/inspections/${record?.inspectionId}/site-details/1`);
  };

  const handleSelectRow = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    internalChangeRef.current = true;
    setSelectedRows((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      }
      return [...prevSelected, id];
    });
  };

  const sortData = (data, key, direction) => [...data].sort((a, b) => {
    let aValue = a[key];
    let bValue = b[key];

    if (Date.parse(aValue) && Date.parse(bValue)) {
      aValue = new Date(aValue);
      bValue = new Date(bValue);
    } else if (
      typeof aValue === 'number'
      && typeof bValue === 'number'
    ) {
      return direction === 'asc' ? aValue - bValue : bValue - aValue;
    } else {
      aValue = String(aValue);
      bValue = String(bValue);
    }

    if (aValue < bValue) return direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return direction === 'asc' ? 1 : -1;
    return 0;
  });

  const onRowClick = (rowData) => {
    onRow(rowData);
  };

  const onSort = (columnKey) => {
    const column = tableColumns.find((col) => col.key === columnKey);
    if (column && column.sortable) {
      if (sortState.key === columnKey && sortState.direction === 'asc') {
        setSortState({ key: columnKey, direction: 'desc' });
      } else if (
        sortState.key === columnKey
        && sortState.direction === 'desc'
      ) {
        setSortState({ key: null, direction: null });
      } else {
        setSortState({ key: columnKey, direction: 'asc' });
      }
    }
  };

  const renderSortIcon = (columnKey) => {
    if (sortState.key === columnKey) {
      return (
        <ArrowContainer>
          <AscIcon isActive={sortState.direction === 'asc'} />
          <DescIcon isActive={sortState.direction === 'desc'} />
        </ArrowContainer>
      );
    }
    return <NeutralIcon />;
  };

  const renderHeaders = (columns) => [
    ...columns.map((column) => (
      <StyledTh
        key={column.key}
        width={column.width}
        isSortable={column.sortable}
        onClick={() => column.sortable && onSort(column.key)}
      >
        <InnerHeader>
          {column.title}
          {column.sortable && renderSortIcon(column.key)}
        </InnerHeader>
      </StyledTh>
    ))
  ];

  const renderRows = (data) => data?.map((item) => (
    <StyledTr key={item.id} onClick={() => onRowClick(item)}>
      {checkbox ? (
        <CheckboxTd>
          <label className="checkbox-group" htmlFor={`checkbox-${item.id}`}>
            <input
              id={`checkbox-${item.id}`}
              type="checkbox"
              checked={selectedRows.includes(item.id)}
              onChange={(event) => handleSelectRow(item.id, event)}
            />
            <span className="custom-checkbox" />
          </label>
        </CheckboxTd>
      ) : null}
      {tableColumns?.map((column) => (
        <StyledTd key={column.key} width={column.width} checkbox={checkbox}>
          {column.render
            ? column.render(item[column.dataIndex], item)
            : item[column.dataIndex]}
        </StyledTd>
      ))}
    </StyledTr>
  ));

  useEffect(() => {
    if (internalChangeRef.current) {
      internalChangeRef.current = false;
      return;
    }
    const sameLength = selectedKeys.length === selectedRows.length;
    const hasSameIds = sameLength && selectedKeys.every((id) => selectedRows.includes(id));
    if (!hasSameIds) {
      setSelectedRows(selectedKeys);
    }
  }, [selectedKeys, selectedRows]);

  useEffect(() => {
    if (inspections?.length > 0) {
      const completeInspections = inspections.filter(
        (insp) => insp?.status?.code && insp.status.code.toLowerCase() === 'complete'
      );

      const groupBySiteId = (insps) => insps.reduce((acc, inspection) => {
        const siteId = inspection?.site?.id;
        if (siteId) {
          if (!acc[siteId]) {
            acc[siteId] = [];
          }
          acc[siteId].push(inspection);
        }
        return acc;
      }, {});

      const locationCounts = groupBySiteId(completeInspections);

      const inspectionsWithValidDate = inspections.map((location) => ({
        ...location,
        sortableDate: new Date(location?.inspectionDate).getTime()
      }));

      const inspectionsFromUniqueSites = [];
      inspectionsWithValidDate.forEach((insp) => {
        if (insp?.status?.code && insp.status.code.toLowerCase() === 'complete') {
          const index = inspectionsFromUniqueSites.findIndex(
            (uniqueLocation) => uniqueLocation?.site?.id === insp?.site?.id
          );
          if (index > -1) {
            if (inspectionsFromUniqueSites[index].sortableDate < insp.sortableDate) {
              inspectionsFromUniqueSites[index] = insp;
            }
          } else {
            inspectionsFromUniqueSites.push(insp);
          }
        }
      });

      inspectionsFromUniqueSites.sort((a, b) => b.sortableDate - a.sortableDate);

      const locationsWithInspections = inspectionsFromUniqueSites.map((inspection) => ({
        ...inspection,
        inspections: locationCounts[inspection?.site?.id]?.length || 0,
        recent: format(new Date(inspection.sortableDate), 'MMMM dd, yyyy')
      }));

      const hasCustomer = Object.keys(params).includes('customerId');

      if (hasCustomer) {
        setLocationToMatchOrgParam(locationsWithInspections);
      } else {
        setLocationData(locationsWithInspections);
      }
    }
  }, [inspections, params]);

  useEffect(() => {
    let filteredData = initialTableData;

    if (filter) {
      const searchableFields = getAllKeys(initialTableData[0]);

      const isMatch = (item) => searchableFields.some((field) => {
        const fieldValue = String(getValueByPath(item, field)).toLowerCase();
        return fieldValue.includes(filter.toLowerCase());
      });

      filteredData = initialTableData.filter(isMatch);
    }

    if (sortState.key && sortState.direction) {
      filteredData = sortData(
        filteredData,
        sortState.key,
        sortState.direction
      );
    }

    setTableData(filteredData);
  }, [sortState, filter, initialTableData]);

  useEffect(() => {
    onRowSelectionChange(selectedRows);
  }, [selectedRows]);

  const [visibleData, setVisibleData] = useState([]);
  const partition = 10;
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(partition * 3);
  const lowerBound = partition * rowHeight;
  const upperBound = partition * 2 * rowHeight;

  const updateIndexRange = (valueIncrement) => {
    setLeftIndex(leftIndex + valueIncrement);
    setRightIndex(rightIndex + valueIncrement);
  };

  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;

    if ((leftIndex === 0 && scrollTop < lowerBound) || tableData.length <= 30) return;

    if (scrollTop > upperBound) {
      const newLeftIdx = leftIndex + partition;
      const newRightIdx = (rightIndex + partition) < tableData.length ? rightIndex + partition : tableData.length;
      updateIndexRange(partition);
      setVisibleData(tableData.slice(newLeftIdx, newRightIdx));
    } else if (scrollTop < lowerBound) {
      const newLeftIdx = leftIndex - partition;
      const newRightIdx = rightIndex - partition;
      setVisibleData(tableData.slice(newLeftIdx, newRightIdx));
      updateIndexRange(-partition);
    }
  };

  useEffect(() => {
    const initialData = tableData?.slice(0, partition * 3);
    setVisibleData(initialData);
  }, [tableData, partition]);

  const renderContent = () => {
    if (isUpload && initialTableData?.length === 0) {
      return (
        <StyledEmptyFiles>
          <h1>No files found</h1>
          <p>You have no files for this type.</p>
        </StyledEmptyFiles>
      );
    }

    const renderEmptyState = (isAdmin) => {
      const title = isAdmin
        ? 'Create an inspection'
        : 'No Inspection Data available';
      const message = isAdmin
        ? 'Create an inspection report to see completed inspections here.'
        : 'This table is currently empty as no inspections have been initiated.';

      return (
        <StyledEmptyState>
          <h1>{title}</h1>
          <p>{message}</p>
        </StyledEmptyState>
      );
    };

    const inProgressInspections = filteredInspections?.filter(
      (item) => item?.status?.code !== null && item?.status?.code !== 'complete'
    );

    const completedInspections = locationData?.filter(
      (item) => item?.status?.code?.toLowerCase() === 'complete'
    );

    const inProgressCount = inProgressInspections?.length;
    const completedCount = completedInspections?.length;

    if (initialTableData?.length === 0 && inspections?.length === 0) {
      return renderEmptyState(is_admin);
    }

    if (enableTabs) {
      return (
        <TableTabs
          onRowCompleted={onRow}
          handleInProgress={handleInProgress}
          data={[inProgressInspections, completedInspections]}
          counts={[inProgressCount, completedCount]}
          filters={[
            {
              title: 'In progress',
              filter: '',
              columns: tableColumns
            },
            {
              title: 'Complete',
              filter: '',
              columns: completedColumns
            }
          ]}
          tableComponent={Table}
        />
      );
    }
    return (
      <StyledSection>
        {searchable
          && (
            <SearchBar
              placeholder={searchPlaceholder}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              size="medium"
              containerClass="search-bar-container"
              disabled={initialTableData?.length === 0}
            />
          )}
        <StyledTable onScroll={handleScroll}>
          <thead>
            <tr>{renderHeaders(tableColumns)}</tr>
          </thead>
          <tbody>{renderRows(visibleData)}</tbody>
        </StyledTable>
      </StyledSection>
    );
  };

  return <StyledDiv tabsEnabled={enableTabs}>{renderContent()}</StyledDiv>;
};

const columnShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  sortable: PropTypes.bool,
  render: PropTypes.func,
  dataIndex: PropTypes.string.isRequired
});

const inspectionShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  createdAt: PropTypes.string,
  createdBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flogistixId: PropTypes.string,
  createdByEmail: PropTypes.string,
  createdByName: PropTypes.string,
  deletedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inspectionDate: PropTypes.string,
  statusId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  statusName: PropTypes.string,
  complianceType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  state: PropTypes.string,
  county: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  gps: PropTypes.string,
  inspectionFrequency: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  operationalStatus: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number
  ]),
  locationSignFileId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filesCount: PropTypes.number,
  customerName: PropTypes.string,
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  customerLocationName: PropTypes.string,
  customerLocationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inspections: PropTypes.number,
  recent: PropTypes.string,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
});

const dataShape = PropTypes.shape({
  inspections: PropTypes.arrayOf(inspectionShape).isRequired,
  executionTime: PropTypes.string.isRequired
});

Table.propTypes = {
  data: dataShape.isRequired,
  columns: PropTypes.arrayOf(columnShape).isRequired,
  completedColumns: PropTypes.arrayOf(columnShape),
  onRow: PropTypes.func,
  checkbox: PropTypes.bool,
  enableTabs: PropTypes.bool,
  isUpload: PropTypes.bool,
  searchable: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  selectedKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  )
};

Table.defaultProps = {
  checkbox: false,
  enableTabs: false,
  isUpload: false,
  searchable: true,
  onRowSelectionChange: () => null,
  completedColumns: {},
  onRow: () => null,
  searchPlaceholder: 'Filter by title',
  selectedKeys: []
};

export default Table;
