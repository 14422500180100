import { API_REPORTING } from '../shared/url';

export const getReports = async (token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` })
  };

  const url = `${API_REPORTING}`;
  const method = 'GET';

  const options = {
    method,
    headers
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
  }

  return response.json();
};

export const getScheduledReports = async (token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` })
  };

  const url = `${API_REPORTING}/schedules`;
  const method = 'GET';

  const options = {
    method,
    headers
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
  }

  return response.json();
};

export const getScheduledTitles = async (token: string) => {
  const response = await getScheduledReports(token);
  const { reports } = response;
  const titles = reports.map((report: { userReportName: string }) => report.userReportName);
  return titles;
};

export const deleteScheduledReport = async (reportName: string, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` })
  };

  const url = `${API_REPORTING}/schedules/${reportName}`;
  const method = 'DELETE';

  const options = {
    method,
    headers
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

export const immediateReportingPost = async (
  data: object,
  token: string,
  selectedReport: string
) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` })
  };

  const url = `${API_REPORTING}/immediate/${selectedReport}`;
  const method = 'POST';

  const options = {
    method,
    headers,
    ...(data && { body: JSON.stringify(data) })
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(`: ${response.status}: ${errorResponse.message}`);
  }

  return response.json();
};

export const scheduledReportingPost = async (
  data: {
    userReportName: string,
    reportName: string,
    cronUtc: string,
    options: {
      orgId: number,
      siteId: string,
      inspectorId: string,
      reportName: string
    }
  },
  token: string
) => {
  const reportName = data.userReportName;
  const userReportName = data.options.reportName;
  const reportNames = await getScheduledTitles(token);
  if (reportNames.includes(reportName) || reportNames.includes(userReportName)) {
    throw new Error('Report name already exists');
  }
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` })
  };

  const url = `${API_REPORTING}/schedules`;
  const method = 'POST';

  const options = {
    method,
    headers,
    ...(data && { body: JSON.stringify(data) })
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

export const scheduledReportPatch = async (
  data: {
    userReportName: string,
    reportName: string,
    cronUtc: string,
    options: {
      orgId: number,
      siteId: string,
      inspectorId: string,
      reportName: string
    }
  },
  token: string
) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` })
  };

  const url = `${API_REPORTING}/schedules/${encodeURIComponent(data.userReportName)}`;
  const method = 'PUT';

  const options = {
    method,
    headers,
    ...(data && { body: JSON.stringify(data) })
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(`: ${response.status}: ${errorResponse.message}`);
  }

  return response.json();
};

export const generateCronExpression = (
  frequency: string,
  time: string,
  day: string | undefined,
  date: Date | undefined
) => {
  const [hour, minute] = time.split(':');
  if (frequency === 'day') {
    return `${minute} ${hour} * * *`;
  } if (frequency === 'week') {
    return `${minute} ${hour} * * ${day}`;
  } if (frequency === 'month') {
    return date ? `${minute} ${hour} ${date.getDate()} * *` : '';
  }
  return '';
};

export const cronToInterval = (cron: string): string => {
  const intervals: string[] = [];

  const [minute, hour, dayOfMonth, month, dayOfWeek] = cron.split(' ');
  if (!minute || !hour) return intervals[0];
  if (dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    intervals.push('Every 24 hours');
  }
  if (dayOfMonth.match(/^\d{1,2}$/) && month === '*' && dayOfWeek === '*') {
    intervals.push('Every Month');
  }
  if (dayOfMonth === '*' && month === '*' && dayOfWeek.match(/^[0-6]$/)) {
    intervals.push('Every Week');
  }

  return intervals[0];
};
