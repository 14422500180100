/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  useState, createContext, useEffect, useMemo
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import {
  AIR_METHANE_ACCESS_GROUPS,
  AIR_METHANE_ADMIN_GROUP_NAME,
  INFINITE,
  homeSections
} from '../shared/constants';
import {
  getOrgs, getSites, getNewInspections, getSitesAndOrgs
} from '../services/queryService';
import {
  addOrUpdateInspections,
  addOrUpdateVersion,
  getAllInspections,
  deleteLocalInspectionById,
  updateAuthTokenForPendingTransactions
} from '../dexie/operations';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [token, setToken] = useState('');
  const [activeHomeSection, setActiveHomeSection] = useState(homeSections.IN_PROGRESS);
  const [userIsInternal, setUserIsInternal] = useState(false);
  const [inspections, setInspections] = useState(null);
  const [inspectionsLoading, setInspectionsLoading] = useState(false);
  const [user_organizations, setOrganizations] = useState(null);
  const [insp_id, setInspId] = useState(null);
  const [user_sites, setSites] = useState(null);
  const [selectInspection, setSelectInspection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orgAndSitesLoading, setLoadingOrgsAndSites] = useState(false);
  const [inspectionsReloadIsPending, setInspectionsReloadIsPending] = useState(false);
  const [is_admin, setIsAdmin] = useState(false);
  const [has_access, setHasAccess] = useState(true);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [inspectionsReadyCount, setInspectionsReadyCount] = useState(0);
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);
  const location = useLocation();

  const [orgs, setOrgs] = useState([]);

  const [globalNotificationType, setGlobalNotificationType] = useState({
    show: false,
    message: '',
    type: '',
    duration: null,
    customTitle: '',
    fixedWidth: ''
  });

  const triggerGlobalNotification = ({
    type,
    message,
    customTitle = '',
    duration = 5000,
    fixedWidth = ''
  }) => {
    setGlobalNotificationType({
      show: true,
      type,
      message,
      customTitle,
      duration,
      fixedWidth
    });
  };

  const closeGlobalNotification = () => {
    setGlobalNotificationType({ show: false, type: '', message: '' });
  };

  const initialValue = () => {
    const savedValue = localStorage.getItem('sideNavVisible');
    return savedValue !== null ? JSON.parse(savedValue) : true;
  };

  const [sideNavVisible, setSideNavVisible] = useState(initialValue());

  const setSideNavVisibility = (visibility) => {
    setSideNavVisible(visibility);
  };

  let netsuiteToAxilSiteIdMap = {};
  let netsuiteToAxilCustomerIdMap = {};

  if (!loading) {
    netsuiteToAxilSiteIdMap = Object.fromEntries(
      user_sites?.map(({ id, axilId }) => [id, axilId])
    );
    netsuiteToAxilCustomerIdMap = Object.fromEntries(
      user_organizations?.map(({ id, axilId }) => [id, axilId])
    );
  }

  const updateInspectionsAndVersions = (incomingInspections) => {
    incomingInspections.forEach(async (inspection) => {
      const { id: inspectionId, ...inspectionData } = inspection;
      await addOrUpdateVersion({ inspectionId, version: inspectionData.version });
      await addOrUpdateInspections({ inspectionId, ...inspectionData });
    });
  };

  const getAndSetInspections = async () => {
    setInspectionsLoading(true);
    if (inspections) {
      setInspectionsReloadIsPending(true);
    }

    const attemptOperationWithRetry = async () => {
      try {
        let newData = {};
        if (!inspectionsLoading || inspectionsReloadIsPending) {
          const newInspections = await getNewInspections(token);
          newData = { inspections: newInspections };
        } else {
          const currentInspections = inspections;
          newData = { inspections: currentInspections };
          newData.inspections = newData.inspections.filter((inspection) => {
            const inspectionExists = inspections.find((existingInspection) => existingInspection.id === inspection.id);
            return !inspectionExists;
          });
        }

        if (newData.inspections) {
          const sortedInspections = [...newData.inspections].sort(
            (a, b) => new Date(b.inspectionDate) - new Date(a.inspectionDate)
          ).map((inspection) => ({ ...inspection, inspectionId: inspection.id }));
          const allLocalInspections = await getAllInspections();
          const deletedInspections = allLocalInspections.filter((insp) => {
            const found = sortedInspections.find((newInsp) => newInsp.id === insp.inspectionId);
            return !found;
          });
          if (deletedInspections.length > 0) {
            for (let i = 0; i < deletedInspections.length; i += 1) {
              // eslint-disable-next-line no-await-in-loop
              await deleteLocalInspectionById(deletedInspections[i].inspectionId);
            }
          }
          setInspections(sortedInspections);
          updateInspectionsAndVersions(sortedInspections);
        }
        if (inspections) {
          setInspectionsReloadIsPending(false);
        }
        return true;
      } catch (error) {
        const localInspections = await getAllInspections();
        const localSortedInspections = localInspections.sort(
          (a, b) => new Date(b.inspectionDate) - new Date(a.inspectionDate)
        );
        setInspections(localSortedInspections);
      }
      return true;
    };

    try {
      await attemptOperationWithRetry();
    } catch (err) {
      setLoading(false);
      triggerGlobalNotification(
        'error',
        `There’s a problem loading your inspections. Try refreshing
        the page. If the problem persists, please contact support for help.`,
        'Failure to load inspections',
        INFINITE
      );
    }
    setInspectionsLoading(false);
  };

  useEffect(() => {
    localStorage.setItem('sideNavVisible', JSON.stringify(sideNavVisible));
  }, [sideNavVisible]);

  const toggleSideNav = () => {
    setSideNavVisible(!sideNavVisible);
  };

  const getAndSetOrgsAndSites = async () => {
    setLoadingOrgsAndSites(true);
    const orgsAndSites = await getSitesAndOrgs(token);
    const orgsWithSites = orgsAndSites.filter((item) => (item.sites && item.sites.length !== 0));

    const uniqueNames = new Set();

    const uniqueData = orgsWithSites.filter((org) => {
      if (uniqueNames.has(org.name)) return false;
      uniqueNames.add(org.name);
      return true;
    });

    setOrgs(uniqueData);
    setLoadingOrgsAndSites(false);
  };

  useEffect(() => {
    setSideNavVisible(!['inspections', 'inspections'].includes(location.pathname.split('/').at(1)));
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      const emailDomain = user?.email?.split('@').pop();
      const allowedDomains = ['flogistix.com'];
      const isInternalUser = allowedDomains.includes(emailDomain);

      setUserIsInternal(isInternalUser);

      (async () => {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
        const decodedToken = jwtDecode(accessToken);
        const groups = decodedToken['http://axil.com/groups'];
        const isAdmin = groups.some((name) => name === AIR_METHANE_ADMIN_GROUP_NAME);
        let hasAccess = false;
        for (let i = 0; i < groups.length; i += 1) {
          if (AIR_METHANE_ACCESS_GROUPS.includes(groups[i])) {
            hasAccess = true;
            break;
          }
        }
        setHasAccess(hasAccess);
        setIsAdmin(isAdmin);
      })();
    }
  }, [isAuthenticated, user?.email, getAccessTokenSilently]);

  useEffect(() => {
    if (token) {
      updateAuthTokenForPendingTransactions(token);
      if (!user_organizations) {
        getOrgs(token, setOrganizations, triggerGlobalNotification);
      }

      if (!user_sites) {
        getSites(token, setSites, triggerGlobalNotification);
      }

      if (!inspectionsLoading && !inspections?.length) {
        getAndSetInspections();
      }

      // calls for setting of data needed in new UI overhaul
      if (!orgs.length && !orgAndSitesLoading) {
        getAndSetOrgsAndSites();
      }
    }
  }, [token]);

  useEffect(() => {
    setLoading(!inspections || !user_organizations || !user_sites);
  }, [inspections, user_organizations, user_sites]);

  useEffect(() => {
    if (inspections) {
      const readyForSubmissionCount = inspections.filter(
        (inspection) => inspection.statusName === 'Ready for submission'
      ).length;
      setInspectionsReadyCount(readyForSubmissionCount);
    }
  }, [inspections]);

  const providerValue = useMemo(
    () => ({
      inspections,
      inspectionsLoading,
      user_organizations,
      user_sites,
      token,
      loading,
      is_admin,
      has_access,
      netsuiteToAxilSiteIdMap,
      netsuiteToAxilCustomerIdMap,
      getAndSetInspections,
      setInspections,
      inspectionsReloadIsPending,
      insp_id,
      setInspId,
      selectInspection,
      setSelectInspection,
      sideNavVisible,
      toggleSideNav,
      setSideNavVisibility,
      profileModalOpen,
      setProfileModalOpen,
      inspectionsReadyCount,
      setInspectionsReadyCount,
      globalNotificationType,
      setGlobalNotificationType,
      triggerGlobalNotification,
      closeGlobalNotification,
      areButtonsDisabled,
      setAreButtonsDisabled,
      activeHomeSection,
      setActiveHomeSection,
      userIsInternal,
      // new UI overhaul states
      orgs
    }),
    [
      inspections,
      inspectionsLoading,
      user_organizations,
      user_sites,
      token,
      loading,
      is_admin,
      has_access,
      netsuiteToAxilSiteIdMap,
      netsuiteToAxilCustomerIdMap,
      inspectionsReloadIsPending,
      insp_id,
      selectInspection,
      sideNavVisible,
      profileModalOpen,
      inspectionsReadyCount,
      globalNotificationType,
      areButtonsDisabled,
      activeHomeSection,
      userIsInternal,
      orgs
    ]
  );

  return (
    <GlobalContext.Provider value={providerValue}>
      {children}
    </GlobalContext.Provider>
  );
};
