import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, FormLabel } from '@flogistix/flo-ui';

import { InspectionContext } from '../../../../context/InspectionContext';
import {
  ComplianceType,
  InspectionFrequency,
  OperationalStatus,
  FlareLightCombustorVerification,
  SiteType
} from '../../../../classes/enums';
import RangerPicker from '../../../../components/RangePicker/RangePicker';

const Info = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext) || {};
  const [searchFrequency, setSearchFrequency] = useState('');
  const [OOOOb, setOOOOb] = useState(false);
  const [complianceType, setComplianceType] = useState(
    inspection?.id ? inspection.complianceType || '' : ''
  );

  const [operationalStatus, setOperationalStatus] = useState(
    inspection?.id ? inspection.operationalStatus || '' : ''
  );

  const [flareLightCombustorVerification, setFlareLightCombustorVerification] = useState(
    inspection?.id ? inspection.flareLightCombustorVerification || '' : ''
  );

  const [inspectionFrequency, setInspectionFrequency] = useState(
    inspection?.id ? inspection.inspectionFrequency || '' : ''
  );
  const [siteType, setSiteType] = useState(
    inspection?.id ? inspection?.siteType || '' : ''
  );

  const handleComplianceTypeChange = (selectedOption: { value: string | number | null }) => {
    const newComplianceType = selectedOption.value as ComplianceType | null;

    if (inspection && token) {
      if (newComplianceType === null) {
        inspection.removeComplianceType(token);
        setComplianceType('');
      } else {
        if (inspection.complianceType !== newComplianceType) {
          inspection.updateComplianceType(token, newComplianceType);
        }
        setComplianceType(newComplianceType);
      }
    }
    setCompletedSectionsOnChange();
  };

  const handleOperationalStatusChange = (selectedOption: { value: string | number | null }) => {
    const newStatus = selectedOption.value as OperationalStatus | null;

    if (inspection && token) {
      if (newStatus === null) {
        inspection.removeOperationalStatus(token);
        setOperationalStatus('');
      } else {
        if (inspection.operationalStatus !== newStatus) {
          inspection.updateOperationalStatus(token, newStatus);
        }
        setOperationalStatus(newStatus);
      }
    }
    setCompletedSectionsOnChange();
  };

  const handleFlareLightCombustorVerificationChange = (selectedOption: { value: string | number | null }) => {
    const newVerification = selectedOption.value as FlareLightCombustorVerification | null;

    if (inspection && token) {
      if (newVerification === null) {
        inspection.removeFlareLightCombustorVerification(token);
        setFlareLightCombustorVerification('');
      } else {
        if (inspection.flareLightCombustorVerification !== newVerification) {
          inspection.updateFlareLightCombustorVerification(token, newVerification);
        }
        setFlareLightCombustorVerification(newVerification);
      }
    }
    setCompletedSectionsOnChange();
  };

  const handleInspectionFrequencyChange = (selectedOption: { value: string | number }) => {
    const newInspectionFrequency = selectedOption.value as InspectionFrequency;

    if (inspection && token) {
      if (!newInspectionFrequency) {
        inspection.removeInspectionFrequency(token);
      } else if (inspection.inspectionFrequency !== newInspectionFrequency) {
        inspection.updateInspectionFrequency(token, newInspectionFrequency);
      }
    }

    setInspectionFrequency(newInspectionFrequency);
    setCompletedSectionsOnChange();
  };

  const handleSiteTypeChange = (selectedOption: { value: string | number | null }) => {
    if (inspection && token) {
      if (!selectedOption.value) {
        inspection.removeSiteType(token);
        setSiteType('');
      } else {
        if (inspection.siteType !== selectedOption.value) {
          inspection.updateSiteType(token, selectedOption.value as SiteType);
        }
        setSiteType(selectedOption.value as string);
      }
    }

    setCompletedSectionsOnChange();
  };

  useEffect(() => {
    if (complianceType === ComplianceType.OOOOb) {
      setOOOOb(true);
    } else {
      setOOOOb(false);
      handleFlareLightCombustorVerificationChange({ value: null });
    }
  }, [complianceType]);

  return (
    <>
      <h1 className="page-details__header">Inspection details</h1>
      <h4 className="part">Part 1 of 4 - Information</h4>

      <FormLabel>Compliance type</FormLabel>
      <Dropdown
        id="compliance-type"
        value={complianceType}
        onSelect={(option) => handleComplianceTypeChange(option)}
        className="input__dropdown"
        options={Object.values(ComplianceType).map((type) => ({
          value: type,
          label: type
        }))}
        placeholder="Select"
        fixedWidth="100%"
        onClear={() => handleComplianceTypeChange({ value: null })}
      />
      <FormLabel>Operational status</FormLabel>
      <Dropdown
        id="operational-status"
        value={operationalStatus}
        onSelect={(option) => handleOperationalStatusChange(option)}
        className="input__dropdown"
        options={Object.values(OperationalStatus).map((status) => ({
          value: status,
          label: status
        }))}
        placeholder="Select"
        fixedWidth="100%"
        onClear={() => handleOperationalStatusChange({ value: null })}
      />
      {OOOOb && (
        <>
          <FormLabel>Site type</FormLabel>
          <Dropdown
            id="facility-type"
            value={siteType}
            onSelect={(option) => handleSiteTypeChange(option)}
            className="input__dropdown"
            options={Object.values(SiteType).map((type) => ({
              value: type,
              label: type
            }))}
            placeholder="Select facility type"
            fixedWidth="100%"
            onClear={() => handleSiteTypeChange({ value: null })}
          />
          <FormLabel>Flare light/Combustor verification</FormLabel>
          <Dropdown
            id="flare-light-combustor-verification"
            value={flareLightCombustorVerification}
            onSelect={(option) => handleFlareLightCombustorVerificationChange(option)}
            className="input__dropdown"
            options={Object.values(FlareLightCombustorVerification).map((verification) => ({
              value: verification,
              label: verification
            }))}
            placeholder="Select"
            fixedWidth="100%"
            onClear={() => handleFlareLightCombustorVerificationChange({ value: null })}
          />
        </>
      )}
      <FormLabel>Inspection frequency</FormLabel>
      <Dropdown
        id="inspection-frequency"
        value={inspectionFrequency || ''}
        onSelect={(option) => handleInspectionFrequencyChange(option)}
        onSearchChange={(value) => setSearchFrequency(value)}
        searchValue={searchFrequency}
        className="input__dropdown"
        options={Object.values(InspectionFrequency).map((frequency) => ({
          value: frequency,
          label: frequency
        }))}
        placeholder="Select frequency"
        fixedWidth="100%"
        onClear={() => handleInspectionFrequencyChange({ value: '' })}
      />

      <FormLabel>Inspection time</FormLabel>
      <div className="input__select">
        <RangerPicker />
      </div>
    </>
  );
};

export default Info;
