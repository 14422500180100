/* eslint @typescript-eslint/no-unused-vars: off */
import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useMemo
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import {
  emptyBaseMonitoredComponent,
  inspectionSections
} from '../shared/constants';
import { GlobalContext } from './GlobalContext';
import { getSitesAndOrgs, getNewInspectors, getNewInstruments } from '../services/queryService';
import { determineSectionCompletion } from '../shared/utils';
import {
  addOrUpdateInspectors, addOrUpdateInstruments, addOrUpdateOrgs, getActiveInspectors,
  getLocalInspectionById
} from '../dexie/operations';

const DEFAULTS = {
  part: 1,
  leakSubNav: 1,
  leakSummarySubNav: 1,
  surveys: [],
  leaks: [],
  verifications: [],
  monitoredComponents: [],
  activeTab: 'site-details',
  activeSubTab: 'info',
  fields: {
    facilitySign: []
  },
  complianceType: 'OOOO',
  operationalStatus: 'Running',
  isLeaks: 'No',
  isOnSite: 'No',
  isDeviations: 'No',
  completedSections: [],
  editInspectionData: {
    isPending: false,
    inspectionData: {},
    inspectionId: null
  },
  clearFields: () => {},
  resetFormLocation: () => {}
};

export const ModifyInspectionsContext = createContext(DEFAULTS);

export const ModifyInspectionsProvider = ({ children }) => {
  const { triggerGlobalNotification, token } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [part, setPart] = useLocalStorage('part', DEFAULTS.part);
  const [leakOGI, setLeakOGI] = useState([]);
  const [inspectors, setInspectors] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [completedSections, setCompletedSections] = useState(
    DEFAULTS.completedSections
  );
  const [facilitySign, setFacilitySign] = useState([]);
  const [sites, setSites] = useLocalStorage('sites', []);
  const [sitesAndOrgs, setSitesAndOrgs] = useState([]);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [inspectionDate, setInspectionDate] = useState('');
  const [leakDate, setLeakDate] = useLocalStorage('part', null);
  const [leakIDOptions, setLeakIDOptions] = useState([]);
  const [totalCompletionPercentage, setTotalCompletionPercentage] = useState(0);
  const [isNewData, setIsNewData] = useState(false);
  const [editInspectionData, setEditInspectionData] = useState(
    DEFAULTS.editInspectionData
  );
  const [fields, setFields] = useLocalStorage('fields', DEFAULTS.fields);
  const [isLeaks, setIsLeaks] = useLocalStorage('isLeaks', DEFAULTS.isLeaks);
  const [formData, setFormData] = useState(DEFAULTS);
  const [leakSubNav, setLeakSubNav] = useLocalStorage(
    'leakSubNav',
    DEFAULTS.leakSubNav
  );
  const [leakSummarySubNav, setLeakSummarySubNav] = useLocalStorage(
    'leakSummarySubNav',
    DEFAULTS.leakSummarySubNav
  );
  const [surveys, setSurveys] = useLocalStorage('surveys', DEFAULTS.surveys);
  const [monitoredComponents, setMonitoredComponents] = useLocalStorage(
    'monitoredComponents',
    DEFAULTS.monitoredComponents
  );
  const [leaks, setLeaks] = useLocalStorage('leaks', DEFAULTS.leaks);
  const [leakRepair, setLeakRepair] = useLocalStorage('leakRepairs', []);
  const [verifications, setVerifications] = useLocalStorage(
    'verifications',
    DEFAULTS.verifications
  );
  const [activeTab, setActiveTab] = useLocalStorage(
    'activeTab',
    DEFAULTS.activeTab
  );
  const [activeSubTab, setActiveSubTab] = useLocalStorage(
    'activeSubTab',
    DEFAULTS.activeSubTab
  );
  const [isDeviations, setIsDeviations] = useLocalStorage(
    'isDeviations',
    DEFAULTS.isDeviations
  );
  const [isOnSite, setOnSite] = useLocalStorage('isOnSite', DEFAULTS.isOnSite);
  const [complianceType, setComplianceType] = useLocalStorage(
    'complianceType',
    DEFAULTS.complianceType
  );
  const [operationalStatus, setOperationalStatus] = useLocalStorage(
    'operationalStatus',
    DEFAULTS.operationalStatus
  );

  useEffect(() => {
    const fetchInspectionData = async () => {
      try {
        const inspectorsData = await getNewInspectors(token);
        const cachedInspectors = await getActiveInspectors();
        const instrumentsData = await getNewInstruments(token);
        const orgsData = await getSitesAndOrgs(token);
        setInspectors(inspectorsData || cachedInspectors);
        setInstruments(instrumentsData);
        const orgsSiteData = orgsData.filter((org) => (org.sites && org.sites.length !== 0));
        setOrgs(orgsSiteData);
      } catch (error) {
        console.error('Failed to fetch inspection data:', error);
      }
    };
    if (token) {
      fetchInspectionData();
    }
  }, [token]);

  const updateLeakIDOptions = (leakOptions) => {
    setLeakIDOptions(leakOptions);
  };

  const addRepairsToLeaks = () => {
    const updatedLeaks = leaks.map((leak) => {
      const repairsForThisLeak = leakRepair.filter(
        (repair) => repair?.leakId === leak.tagNumber
            || repair?.leakId === leak.id
            || repair?.leakId === leak.localId
      );
      return { ...leak, leakRepairs: repairsForThisLeak };
    });
    setLeaks(updatedLeaks);
  };

  const updateLeakWithRepair = (leakId, updatedRepair) => leaks.map((leak) => {
    if (leak.tagNumber === leakId) {
      const updatedRepairs = leak.leakRepairs.map((repair) => {
        if (repair.localId === updatedRepair.localId) {
          return updatedRepair;
        }
        return repair;
      });
      return {
        ...leak,
        leakRepairs: updatedRepairs?.filter((repair) => repair?.leakId === leak?.tagNumber)
      };
    }
    return {
      ...leak,
      leakRepairs: leak?.leakRepairs?.filter((repair) => repair?.leakId === leak?.tagNumber)
    };
  });

  const formatPayloadForServer = (leaksData) => leaksData?.map(({ localId: outerLeakId, ...leak }) => ({
    ...leak,
    leakRepairs: leak?.leakRepairs?.map(
      ({ localId, leakId, ...rest }) => (outerLeakId ? { ...rest } : { ...rest, leakId })
    )
  }));

  const clearFields = () => {
    setFields({});
    setSurveys([]);
    setLeaks(leaks?.map((leak) => ({ ...leak, leakRepairs: [] })));
    setLeaks([]);
    setLeakRepair([]);
    setVerifications([]);
    setInspectionDate('');
    setIsLeaks('No');
    setActiveTab('site-details');
    setOnSite('No');
    setStartTime(null);
    setEndTime(null);
    setPart(1);
    setLeakSubNav(1);
    setLeakSummarySubNav(1);
    setMonitoredComponents(DEFAULTS.monitoredComponents);
    setCompletedSections(DEFAULTS.completedSections);
    setFacilitySign(null);
    localStorage.setItem('leaks', JSON.stringify([]));
    localStorage.setItem('leakRepairs', JSON.stringify([]));
  };

  const removeLocalIdFromArrayOfItems = (items) => items?.map(({ localId, ...rest }) => rest);

  const formatDateToYYYYMMDD = (date) => {
    if (!date) return null;
    return date.toISOString().split('T')[0];
  };

  const handleFormData = () => {
    const fieldsCopy = { ...fields };
    const {
      monitoredComponents: monitoredComponentsCopy,
      surveys: surveysCopy,
      verifications: verificationsCopy
    } = fieldsCopy;
    if (fieldsCopy.inspectionDate) {
      fieldsCopy.inspectionDate = formatDateToYYYYMMDD(new Date(fieldsCopy.inspectionDate));
    }
    const monitoredComponentsWithoutLocalId = removeLocalIdFromArrayOfItems(
      monitoredComponentsCopy
    );
    const surveysWithoutLocalId = removeLocalIdFromArrayOfItems(surveysCopy);
    const verificationsWithoutLocalId = removeLocalIdFromArrayOfItems(verificationsCopy);

    const modifiedSurveys = surveysWithoutLocalId
      ?.map((survey) => {
        const surveyCompletionPercent = determineSectionCompletion(
          inspectionSections.SURVEY_DETAILS,
          survey
        );
        if (surveyCompletionPercent === 0) return null;
        if (survey.leakDetections && survey.leakDetections.length === 0) {
          const { ...surveyWithoutLeakDetections } = survey;
          return surveyWithoutLeakDetections;
        }
        return survey;
      })
      .filter((survey) => survey !== null);

    const modifiedMonitoredComponents = monitoredComponentsWithoutLocalId
      ?.map((component) => {
        const { ...restOfComponent } = component;
        const defaultEntries = Object.entries(emptyBaseMonitoredComponent);
        let hasNonDefaultValues = false;
        for (let i = 0; i < defaultEntries.length; i += 1) {
          if (restOfComponent[defaultEntries[i][0]] !== defaultEntries[i][1]) {
            hasNonDefaultValues = true;
            break;
          }
        }
        return hasNonDefaultValues ? component : null;
      })
      .filter((component) => component !== null);

    const formattedFields = {
      ...fieldsCopy,
      leaks: formatPayloadForServer(fields.leaks),
      surveys: modifiedSurveys,
      verifications: verificationsWithoutLocalId,
      monitoredComponents: modifiedMonitoredComponents
    };

    setFormData({
      ...formattedFields,
      statusId: 1
    });
  };

  const resetFormLocation = () => {
    setPart(DEFAULTS.part);
    setActiveTab(DEFAULTS.activeTab);
    setActiveSubTab(DEFAULTS.activeSubTab);
  };

  const resetEditInspectionData = () => setEditInspectionData(DEFAULTS.editInspectionData);

  const bulkSetContextFields = (inspectionData) => {
    const updatedData = inspectionData ?? {};
    updatedData.leaks = inspectionData?.leaks?.map((leak) => ({
      ...leak,
      localId: uuidv4(),
      leakRepairs: leak?.leakRepairs?.map((repair) => ({
        ...repair,
        leakId: leak?.tagNumber,
        localId: uuidv4()
      }))
    })) || [];
    updatedData.surveys = inspectionData?.surveys?.map((survey) => ({ ...survey, localId: uuidv4() })) || [];
    updatedData.monitoredComponents = inspectionData?.monitoredComponents?.map(
      (component) => ({ ...component, localId: uuidv4() })
    ) || [];
    updatedData.verifications = inspectionData?.verifications?.map(
      (verification) => ({ ...verification, localId: uuidv4() })
    ) || [];
    updatedData.files = [];
    const allLeakRepairs = (inspectionData?.leaks || [])
      .flatMap((leak) => (leak.leakRepairs || [])
        .map((repair) => ({
          ...repair,
          leakId: leak.tagNumber
        }))).filter((repair) => repair !== null);
    setFacilitySign(inspectionData?.locationSignFile || {});
    setFields({
      ...inspectionData,
      monitoredComponents: updatedData.monitoredComponents,
      inspectionDate: inspectionData?.inspectionDate
        ? formatDateToYYYYMMDD(new Date(inspectionData.inspectionDate)) : ''
    });
    setStartTime(inspectionData?.startTime);
    setEndTime(inspectionData?.endTime);
    setInspectionDate(
      inspectionData?.inspectionDate ? formatDateToYYYYMMDD(new Date(inspectionData.inspectionDate)) : ''
    );
    setComplianceType(inspectionData?.complianceType || 'OOOO');
    setOperationalStatus(inspectionData?.operationalStatus || 'Running');
    setVerifications(updatedData.verifications);
    setSurveys(updatedData.surveys);
    setLeaks(updatedData.leaks);
    setLeakRepair(allLeakRepairs);
    setMonitoredComponents(inspectionData?.monitoredComponents);
  };

  const getInspectionById = async (inspectionId) => {
    try {
      setEditInspectionData({
        inspectionId,
        isPending: true,
        inspectionData: null
      });
      const inspectionData = await getLocalInspectionById(inspectionId);
      if (inspectionData?.id) {
        bulkSetContextFields(inspectionData);
        setEditInspectionData({
          ...editInspectionData,
          inspectionData: {
            ...inspectionData,
            inspectionDate: inspectionData.inspectionDate ? formatDateToYYYYMMDD(
              new Date(inspectionData.inspectionDate)
            ) : ''
          },
          isPending: false
        });
      } else {
        setEditInspectionData(DEFAULTS.editInspectionData);
      }
    } catch (error) {
      console.error('Error fetching inspection:', error);
    }
  };

  useEffect(() => {
    bulkSetContextFields(editInspectionData.inspectionData);
  }, [editInspectionData.inspectionData]);

  useEffect(() => {
    if (inspectors.length) {
      inspectors.map((inspector) => addOrUpdateInspectors({ ...inspector, inspectorId: `${inspector.id}` }));
    }
    if (instruments.length) {
      instruments.map((instrument) => addOrUpdateInstruments({ ...instrument, instrumentId: instrument.id }));
    }
    if (orgs.length) {
      const orgsNoDuplicates = orgs.filter((org, index, self) => index === self.findIndex((t) => t.id === org.id));
      orgsNoDuplicates.map((org) => addOrUpdateOrgs(org));
    }
  }, [
    inspectors,
    instruments,
    orgs
  ]);

  useEffect(() => {
    setLeakIDOptions(
      leaks.map((leak) => ({
        id: leak?.id,
        tagNumber: leak?.tagNumber,
        value: leak?.tagNumber?.toString(),
        label: leak?.tagNumber?.toString()
      }))
    );
  }, [leaks]);

  const formattedDate = inspectionDate && formatDateToYYYYMMDD(new Date(inspectionDate));

  useEffect(() => {
    addRepairsToLeaks();
  }, [leakRepair]);

  useEffect(() => {
    setFields((prevFields) => ({
      ...prevFields,
      surveys,
      monitoredComponents,
      leaks,
      verifications,
      facilitySign,
      leakOGI,
      complianceType,
      operationalStatus,
      isLeaks,
      isDeviations,
      isOnSite,
      startTime,
      endTime,
      inspectionDate: formattedDate
    }));
  }, [
    surveys,
    monitoredComponents,
    leaks,
    verifications,
    facilitySign,
    leakOGI,
    complianceType,
    isLeaks,
    isDeviations,
    isOnSite,
    operationalStatus,
    startTime,
    endTime,
    inspectionDate,
    leakRepair
  ]);

  const providerValue = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      activeSubTab,
      setActiveSubTab,
      surveys,
      setSurveys,
      monitoredComponents,
      setMonitoredComponents,
      leaks,
      setLeaks,
      leakRepair,
      setLeakRepair,
      verifications,
      setVerifications,
      updateLeakIDOptions,
      fields,
      setFields,
      leakOGI,
      setLeakOGI,
      facilitySign,
      setFacilitySign,
      complianceType,
      setComplianceType,
      isLeaks,
      isDeviations,
      setIsLeaks,
      setIsDeviations,
      operationalStatus,
      setOperationalStatus,
      startTime,
      setStartTime,
      endTime,
      setEndTime,
      sites,
      setSites,
      part,
      setPart,
      inspectionDate,
      setInspectionDate,
      leakDate,
      setLeakDate,
      leakSubNav,
      setLeakSubNav,
      leakSummarySubNav,
      setLeakSummarySubNav,
      isOnSite,
      setOnSite,
      leakIDOptions,
      formData,
      clearFields,
      updateLeakWithRepair,
      completedSections,
      setCompletedSections,
      editInspectionData,
      resetEditInspectionData,
      setEditInspectionData,
      getInspectionById,
      resetFormLocation,
      inspectors,
      isNewData,
      setFormData,
      setIsNewData,
      sitesAndOrgs,
      orgs,
      instruments,
      formatDateToYYYYMMDD,
      totalCompletionPercentage,
      setTotalCompletionPercentage
    }),
    [
      activeTab,
      activeSubTab,
      surveys,
      monitoredComponents,
      leaks,
      leakRepair,
      verifications,
      fields,
      leakOGI,
      facilitySign,
      complianceType,
      isLeaks,
      isDeviations,
      operationalStatus,
      startTime,
      endTime,
      sites,
      part,
      inspectionDate,
      leakDate,
      leakSubNav,
      leakSummarySubNav,
      isOnSite,
      leakIDOptions,
      formData,
      completedSections,
      editInspectionData,
      inspectors,
      instruments,
      isNewData,
      setIsNewData,
      orgs,
      totalCompletionPercentage
    ]
  );

  return (
    <ModifyInspectionsContext.Provider value={providerValue}>
      {children}
    </ModifyInspectionsContext.Provider>
  );
};
